import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import Menu from './components/menu';
import Start from './Components/start';
import Contact from './Components/contact';
import PersonalData from './Components/personal-data';
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import useSave from './Components/ContextAndHooks/save-context';
import SaveChanges from './Components/save-changes';
import WorkExperience from './Components/work-experience';
import Educations from './Components/educations';
import GitHub from './Components/git-hub';
import WorkingOnIt from './Components/working-on-it';

export const App = () => {

    const save = useSave();

    return (
        <>
            <div id="startRow" className="content row">
                <Routes>
                    <Route path='/' element={<Menu />}>
                        <Route path="/" element={<Navigate to="/start" replace />} />
                        <Route path="/start" element={<Start />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/personal-data" element={<PersonalData />} />
                        <Route path="/work-experience" element={<WorkExperience />} />
                        <Route path="/educations" element={<Educations />} />
                        <Route path="/github" element={<GitHub />} />
                        <Route path="/working-on-it" element={<WorkingOnIt />} />

                    </Route>
                </Routes>
            </div>
        </>
    );
}