import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import { useForm } from "react-hook-form";
import urls from '../Model/fetch-url';
import "./chose-user.css";
import GetUserData from '../Model/get-user-data';

const ChoseUser = (props) => {
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const [error, setError] = useState(false);
    const [users, setUsers] = useState([])
    const user = useUser();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (submitData) => {
        if (submitData.chosenUser) {
            props.openUserEditor(submitData.chosenUser)
            props.closeModal();
        } else {
            setError(true);
        }
    };

    useEffect(() => {
        (async () => {
            const [data, err] = await GetUserData(urls.users, user.token)
            setLoading(false);
            setErr(err);
            data.forEach(user => {
                setUsers(users => [...users, {
                    user,
                    checkboxStatus: false
                }]);
            });
        })();
    }, [])

    useEffect(() => {
        return () => {
            setError(false);
        };
    }, [])

    const handleCheckboxStatus = (e) => {
        let changedUsers = [...users];
        changedUsers.forEach(u => {
            if (u.checkboxStatus === e.target.checked && u.user.userId === e.target.id) {
                u.checkboxStatus = !e.target.checked;
            }
            else if (u.checkboxStatus !== e.target.checked && u.user.userId === e.target.id) {
                u.checkboxStatus = e.target.checked;
            } else {
                u.checkboxStatus = false;
            }
        });
        setUsers(changedUsers);
    };

    return (
        <>
            <h2 id="header">Användare</h2>
            <form
                onSubmit={handleSubmit(onSubmit)}>
                {users.map((user) =>
                (
                    <div className="viewerContainer" key={user.user.userId}>
                        <label>
                            <div key={user.userId}>
                                <input
                                    {...register("chosenUser")}
                                    id={user.user.userId}
                                    value={user.user.userId}
                                    type="checkbox"
                                    checked={user.checkboxStatus}
                                    onClick={(e) => { handleCheckboxStatus(e) }}
                                />
                                <div>{user.user.firstName} {user.user.lastName}</div>
                                <div>{user.user.isViewer ? <small>• Besökare</small> : null}</div>
                                <div>{user.user.isAdmin ? <small>• Administratör </small> : null}</div>
                            </div>
                        </label>
                        <p className="border-top my-3"></p>
                    </div>
                ))}
                {error ?
                    <p id="loginError" className='text-danger'>Du behöver välja en användare</p>
                    :
                    null}
                <div id="btnContainer">
                    <button type="submit" className="btn btn-primary btn-sm mx-2">Redigera användare</button>
                </div>
            </form>
        </>
    )
}
export default ChoseUser;