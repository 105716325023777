import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import useSave from './ContextAndHooks/save-context';
import useEditable from './ContextAndHooks/editable-context';
import HeaderImages from './header-images';
import './contact.css';
import SaveChanges from './save-changes';
import HideIfViewer from './hide-if-viewer';
import EditInfo from './edit-info';

const Contact = (props) => {

    const user = useUser();
    const save = useSave();
    const editable = useEditable();
    const [activatedLinks, setActivatedLinks] = useState(true);

    return (
        <>
            <div className="col">
                <div className="row rightSide">
                    <HeaderImages />
                    <div className="row gx-0">
                        <div id="infoText" className="col-sm text">
                            <h1>Kontaktuppgifter</h1>
                            <p className="border-top border-dark my-3 row"></p>
                            <div id="personalDataRow" className="row">

                                <dt className="col-sm-4">Adress:</dt>
                                <dd className="col-sm-8">
                                    <div className={`d-inline-block ${editable.editable}`}>
                                        <EditInfo initialText='Postadress' setSaveContextKey={save.setUserKey} thisKey='postalAddress' savePlace="user" thisId={user.user.userId} thisInfo={ user.user.postalAddress } />
                                    </div>
                                    <SaveChanges btnId={user.user.userId + '/postalAddress'} />
                                    ,
                                    <span id="space"></span>
                                    <div className={`d-inline-block ${editable.editable}`}>
                                        <EditInfo initialText='Postnummer' setSaveContextKey={save.setUserKey} thisKey='zipCode' savePlace="user" thisId={user.user.userId} thisInfo={ user.user.zipCode } />
                                    </div>
                                    <SaveChanges btnId={user.user.userId + '/zipCode'} />
                                    <span id="space"></span>
                                    <div className={`d-inline-block ${editable.editable}`}>
                                        <EditInfo initialText='Ort' setSaveContextKey={save.setUserKey} thisKey='city' savePlace="user" thisId={user.user.userId} thisInfo={ user.user.city } />
                                    </div>
                                    <SaveChanges btnId={user.user.userId + '/city'} />
                                </dd>

                                <dt className="col-sm-4">Telefon:</dt>
                                <dd className="col-sm-8">
                                    <div className={`d-inline-block ${editable.editable}`}>
                                        {activatedLinks ?
                                            <a href={`tel:${user.user.phoneNumber}`}>
                                                {user.user.phoneNumber? user.user.phoneNumber : 'Telefonnummer'}
                                            </a>
                                            :
                                            <EditInfo initialText='Telefonnummer' setSaveContextKey={save.setUserKey} thisKey='phoneNumber' savePlace="user" thisId={user.user.userId} thisInfo={ user.user.phoneNumber } />
                                        }
                                    </div>
                                    <SaveChanges btnId={user.user.userId + '/phoneNumber'} />
                                </dd>

                                <dt className="col-sm-4">E-post:</dt>
                                <dd className="col-sm-8">
                                    <div className={`d-inline-block ${editable.editable}`}>
                                        {activatedLinks ?
                                            <a href={`mailto:${user.user.email}`}>
                                                {user.user.email? user.user.email : 'E-post'}
                                            </a>
                                            :
                                            <EditInfo initialText='E-post' setSaveContextKey={save.setUserKey} thisKey='email' savePlace="user" thisId={user.user.userId} thisInfo={ user.user.email } />
                                        }
                                    </div>
                                    <SaveChanges btnId={user.user.userId + '/email'} />
                                </dd>

                                <dt className="col-sm-4">Linked In:</dt>
                                <dd className="col-sm-8">
                                    <div className={`d-inline-block ${editable.editable}`}>
                                        {activatedLinks ?
                                            <>
                                                {user.user.cv.linkedInUrl ?
                                                    <a target="_blank" href={`https://${user.user.cv.linkedInUrl}`}>
                                                        {user.user.cv.linkedInUrl}
                                                    </a>
                                                    :
                                                    <a href='#'>
                                                        Url till din Linked In-profil
                                                    </a>
                                                }
                                            </>
                                            :
                                            <EditInfo initialText='Url till din Linked In-profil' setSaveContextKey={save.setCvKey} thisKey='linkedInUrl' savePlace="cv" thisId={user.user.cv.cvId} thisInfo={ user.user.cv.linkedInUrl } />
                                        }
                                    </div>
                                    <SaveChanges btnId={user.user.cv.cvId + '/linkedInUrl'} />
                                </dd>

                                <dt className="col-sm-4">Git Hub:</dt>
                                <dd className="col-sm-8">
                                    <div className={`d-inline-block ${editable.editable}`}>
                                        {activatedLinks ?
                                            <>
                                                {user.user.cv.gitHubUrl ?
                                                    <a target="_blank" href={`https://${user.user.cv.gitHubUrl}`}>
                                                        {user.user.cv.gitHubUrl}
                                                    </a>
                                                    :
                                                    <a href='#'>
                                                        Url till GitHub-sida
                                                    </a>
                                                }
                                            </>
                                            :
                                            <EditInfo initialText='Url till GitHub-sida' setSaveContextKey={save.setCvKey} thisKey='gitHubUrl' savePlace="cv" thisId={user.user.cv.cvId} thisInfo={ user.user.cv.gitHubUrl } />
                                        }
                                    </div>
                                    <SaveChanges btnId={user.user.cv.cvId + '/gitHubUrl'} />
                                </dd>
                            </div>
                            <HideIfViewer>
                                {activatedLinks ?
                                    <button onClick={(e) => { setActivatedLinks(false) }} id="primaryLanguage" type="button" className="btn btn-info btn-sm">
                                        Inaktivera länkar för redigering
                                    </button>
                                    :
                                    <button onClick={(e) => { setActivatedLinks(true) }} id="primaryLanguage" type="button" className="btn btn-info btn-sm">
                                        Aktivera länkar
                                    </button>
                                }
                            </HideIfViewer>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Contact;