const apiRoot = 'cv.nbjork.se';
//const apiRoot = 'localhost:5003';
//const apiRoot = '192.168.1.43:5003';

const urls = {
    rootApi: `https://${apiRoot}`,
    users: `https://${apiRoot}/api/users`,
    cv: `https://${apiRoot}/api/cv`,
    civilStatus: `https://${apiRoot}/api/civilstatus`,
    image: `https://${apiRoot}`,
    language: `https://${apiRoot}/api/languages`,
    profileImage: `https://${apiRoot}/api/users/profileimage`,
    authenticate: `https://${apiRoot}/api/users/authenticate`,
    register: `https://${apiRoot}/api/users/register`,
    registerViewer: `https://${apiRoot}/api/viewer/register`,
    workExperience: `https://${apiRoot}/api/workexperience`,
    workCertificate: `https://${apiRoot}/api/WorkExperience/workcertificate`,
    Viewer: `https://${apiRoot}/api/viewer`,
    educations: `https://${apiRoot}/api/Educations`,
    grade: `https://${apiRoot}/api/Educations/grade`,
    admin: `https://${apiRoot}/api/users/admin`,
    welcomeMail: `https://${apiRoot}/api/Mail/welcome`,
}

export default urls;