import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import { useForm } from "react-hook-form";
import urls from '../Model/fetch-url';
import "./new-cv-form.css";
import GetUserData from '../Model/get-user-data';
import PatchData from '../Model/patch-data';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import DeleteData from '../Model/delete-data';

const HandleUserForm = (props) => {
    const [admin, setAdmin] = useState(false);
    const [viewer, setViewer] = useState(false);
    const [fetchedUser, setFetchedUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const user = useUser();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (submitData) => {
        submitData.isAdmin = admin;
        (async () => {
            const [data, err] = await PatchData(urls.admin + '/' + props.userId, user.token, submitData)
            setLoading(false);
            setErr(err);
            if (data) {
                NotificationManager.success('Användare ändrad');
            }
            if (err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }
        })();
        user.updateUser();
        props.closeModal();
    };

    const deleteUser = () => {
        (async () => {
            const [data, err] = await DeleteData(urls.users, user.token, props.userId)
            setLoading(false);
            setErr(err);
            if (data) {
                NotificationManager.success('Användare borttagen');
            }
            if (err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }
        })();
        props.closeModal();
    }

    const changeValue = (e) => {
        if (e.target.value === 'false') {
            if (e.target.id === 'isAdmin') {
                setAdmin(true);
            }
            else if (e.target.id === 'isViewer') {
                setViewer(true);
            }
        }
        else if (e.target.value === 'true') {
            if (e.target.id === 'isAdmin') {
                setAdmin(false);
            }
            else if (e.target.id === 'isViewer') {
                setViewer(false);
            }
        }
    }

    useEffect(() => {
        (async () => {
            const [data, err] = await GetUserData(urls.users + '/' + props.userId, user.token)
            setLoading(false);
            setErr(err);
            setFetchedUser(data);
        })();
    }, [])

    useEffect(() => {
        if(fetchedUser) {
            setAdmin(fetchedUser.isAdmin);
            setViewer(fetchedUser.isViewer);
        }
    }, [fetchedUser])

    if (fetchedUser) {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>

                <h3>{fetchedUser.firstName} {fetchedUser.lastName}</h3>

                <label>Ändra lösenord<br />
                    <input
                        {...register("password", {
                        })}
                        type="password"
                    />
                </label>
                <div>
                    <small className='error text-danger'>{errors.email?.message}</small>
                </div>
                <label>Administratör:
                    <input
                        {...register("isAdmin")}
                        type="checkbox"
                        checked={ admin }
                        value={ admin }
                        id='isAdmin'
                        onClick={(e) => { changeValue(e) }}
                    />
                </label>

                <div className='d-inline-block my-3'>
                    <button type="submit" className="btn btn-primary btn-sm my-2 mx-2">
                        Spara användare
                    </button>
                </div>
                <div className='d-inline-block'>
                    <button onClick={() => { deleteUser() }} type="button" className="btn btn-danger btn-sm my-2 mx-2">
                        Ta bort
                    </button>
                </div>
            </form>
        )
    }
}
export default HandleUserForm;