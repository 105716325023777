////@ts-check
import React, { useState, useEffect, useContext, createContext } from 'react';
import getUserData from '../../Model/get-user-data';
import urls from '../../Model/fetch-url';
import { useStorageState } from 'react-storage-hooks';

const UserContext = createContext([]);

export const UserContextProvider = (props) => {
    const [token, setToken] = useStorageState(localStorage, 'token', null);
    const [isLoggedIn, setIsLoggedIn] = useStorageState(localStorage, 'isLogedIn', false);
    const [update, setUpdate] = useState(false);
    const [user, setUser] = useState({});
    const [isViewer, setIsViewer] = useState(false);
    const [allowedReferenceAndGrade, setAllowedReferenceAndGrade] = useState(false);
    const [userId, setUserId] = useStorageState(localStorage, 'userId', false);
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();

    useEffect(() => {
        (async () => {
            const [data, err] = await getUserData(urls.users + '/' + userId, token)

            if (data) {
                setUser(data);
            }
            setLoading(false);
            setErr(err);
        })();

        (async () => {
            const [data, err] = await getUserData(urls.Viewer, token)
            setLoading(false);
            setErr(err);
            if (data) {
                setIsViewer(data.isViewer);
                setAllowedReferenceAndGrade(data.viewAllowedReferenceAndGrade);
            }
        })();

    }, [update])

    // useEffect(() => {
    //     console.log('user in context: ', user);
    // }, [])

    const updateUser = () => {
        if (update === false) {
            setUpdate(true);
        } else {
            setUpdate(false)
        }
    }

    if (user) {
        return (
            <UserContext.Provider value={{
                user: user,
                setUser: setUser,
                isViewer: isViewer,
                setIsViewer: setIsViewer,
                allowedReferenceAndGrade: allowedReferenceAndGrade,
                setAllowedReferenceAndGrade: setAllowedReferenceAndGrade,
                userId: userId,
                setUserId: setUserId,
                update: update,
                updateUser: updateUser,
                loading: loading,
                err: err,
                setErr: setErr,
                isLoggedIn: isLoggedIn,
                setIsLoggedIn: setIsLoggedIn,
                token: token,
                setToken: setToken,
            }}>
                {props.children}
            </UserContext.Provider>
        );
    }
}

export default () => useContext(UserContext);