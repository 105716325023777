import { createRoot } from 'react-dom/client';
import { App } from './app';
import Login from './Components/login';
import { HashRouter } from "react-router-dom";
import { UserContextProvider } from './Components/ContextAndHooks/user-context';
import { SaveProvider } from './Components/ContextAndHooks/save-context';
import { EditableProvider } from './Components/ContextAndHooks/editable-context';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { BlinkingEyeProvider } from './BlinkingEye/blinking-eye-btn-context';
import Favicon from 'react-favicon';
import { imageUrls } from './Helpers/images';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <>
        <Favicon url={imageUrls.myCvIco} />
        <EditableProvider>
            <SaveProvider>
                <BlinkingEyeProvider>
                    <UserContextProvider>
                        <Login>
                            <HashRouter>
                                <App />
                            </HashRouter>
                        </Login>
                    </UserContextProvider>
                </BlinkingEyeProvider>
            </SaveProvider>
        </EditableProvider>
    </>
);