import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import useSave from './ContextAndHooks/save-context';
import "./save-btn.css";

const SaveBtn = (props) => {

    const user = useUser();
    const save = useSave();

    return (
        <button onClick={(e) => { props.onClick(e) }} id={props.btnId} name="saveChangesBtn" type="button" className={`save-button btn btn-sm mx-3 py-0 ${props.class}`}>
            Spara ändring
        </button>
    )
}
export default SaveBtn;