import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import { useForm } from "react-hook-form";
import urls from '../Model/fetch-url';
import "./new-cv-form.css";
import PostRegisterViewer from '../Model/post-register-viewer';
import GetUserData from '../Model/get-user-data';
import PatchData from '../Model/patch-data';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const EditViewerForm = (props) => {
    const [allowReferenses, setAllowReferenses] = useState(false);
    const [viewer, setViewer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const user = useUser();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (submitData) => {
        if (allowReferenses === true) {
            submitData.viewAllowedReferenceAndGrade = true;
        }

        (async () => {
            const [data, err] = await PatchData(urls.Viewer + '/' + props.viewerId, user.token, submitData)
            setLoading(false);
            setErr(err);
            if(data) {
                NotificationManager.success('Besökare sparad');
            }
            if(err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }
        })();
        props.closeModal();
    };

    useEffect(() => {
        (async () => {
            const [data, err] = await GetUserData(urls.Viewer + '/' + props.viewerId, user.token)
            setLoading(false);
            setErr(err);
            setViewer(data);
        })();
    },[])

    useEffect(() => {
        if (viewer) {
            setAllowReferenses(viewer.viewAllowedReferenceAndGrade);
        }
    }, [viewer])

    const changeValue = (e) => {
        if (e.target.value === 'false') {
            setAllowReferenses(true);
        } else setAllowReferenses(false);
    }

    if (viewer) {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <label>Förnamn<br />
                    <input
                        defaultValue={viewer.firstName}
                        {...register("firstName", {
                            required: "Fältet är obligatoriskt"
                        })}
                        type="text"
                    />
                </label>
                <div>
                    <small className='error text-danger'>{errors.lastName?.message}</small>
                </div>
                <label>Efternamn<br />
                    <input
                        defaultValue={viewer.lastName}
                        {...register("lastName", {
                            required: "Fältet är obligatoriskt"
                        })}
                        type="text"
                    />
                </label>
                <div>
                    <small className='error text-danger'>{errors.lastName?.message}</small>
                </div>
                <label>E-post<br />
                    <input
                        defaultValue={viewer.email}
                        {...register("email", {
                            required: "Fältet är obligatoriskt"
                        })}
                        type="email"
                    />
                </label>
                <div>
                    <small className='error text-danger'>{errors.email?.message}</small>
                </div>

                <label>Företag<br />
                    <input
                        defaultValue={viewer.viewCompany}
                        {...register("viewCompany", {
                            required: "Fältet är obligatoriskt"
                        })}
                        type="text"
                    />
                </label>
                <div>
                    <small className='error text-danger'>{errors.viewCompany?.message}</small>
                </div>
                
                <label>Tillåt att se referenser:<br />
                    <input
                        {...register("viewAllowedReferenceAndGrade")}
                        type="checkbox"
                        checked={allowReferenses}
                        value={allowReferenses}
                        onChange={(e) => { changeValue(e) }}
                    />
                </label>

                <div id="btnContainer">
                    <button type="submit" className="btn btn-primary btn-sm mx-2">Redigera konto</button>
                </div>
            </form>
        )
    }
}
export default EditViewerForm;