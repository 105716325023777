import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import { useForm } from "react-hook-form";
import urls from '../Model/fetch-url';
import "./chose-viewer.css";
import GetUserData from '../Model/get-user-data';

const ChoseViewer = (props) => {
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const [error, setError] = useState(false);
    const [viewers, setViewers] = useState([])
    const user = useUser();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (submitData) => {
        if(submitData.chosenViewer) {
            props.openViewerEditor(submitData.chosenViewer)
            props.closeModal();
        } else {
            setError(true);
        }
    };

    useEffect(() => {
        (async () => {
            const [data, err] = await GetUserData(urls.Viewer + "/viewers", user.token)
            setLoading(false);
            setErr(err);
            data.forEach(viewer => {
                setViewers(viewers => [...viewers, {
                    viewer,
                    checkboxStatus: false
                }]);
            });
        })();
    }, [])

    useEffect(() => {
        return () => {
            setError(false);
        };
    }, [])

    const handleCheckboxStatus = (e) => {
        let changedViewers = [...viewers];
        changedViewers.forEach(v => {
            if (v.checkboxStatus === e.target.checked && v.viewer.id === e.target.id) {
                v.checkboxStatus = !e.target.checked;
            }
            else if (v.checkboxStatus !== e.target.checked && v.viewer.id === e.target.id) {
                v.checkboxStatus = e.target.checked;
            } else {
                v.checkboxStatus = false;
            }
        });
        setViewers(changedViewers);
    };

    return (
        <>
            <h2 id="visitorHeader">Dina besökare</h2>
            <form
            onSubmit={handleSubmit(onSubmit)}>
                {viewers.map((viewer) =>
                (
                    <div className="viewerContainer" key={viewer.viewer.id}>
                        <label>
                            <div>
                                <input
                                    {...register("chosenViewer")}
                                    id={viewer.viewer.id}
                                    value={viewer.viewer.id}
                                    type="checkbox"
                                    checked={viewer.checkboxStatus}
                                    onClick={(e) => { handleCheckboxStatus(e) }}
                                />
                                {viewer.viewer.firstName} {viewer.viewer.lastName}</div>
                                Företag: {viewer.viewer.viewCompany}<br></br>
                                Antal besök: {viewer.viewer.views}
                        </label>
                    </div>
                ))}
                {error ?
                <p id="loginError" className='text-danger'>Du behöver välja en besökare</p>
                :
                null}
                <div id="btnContainer">
                    <button type="submit" className="btn btn-primary btn-sm mx-2">Redigera besökare</button>
                </div>
            </form>
        </>
    )
}
export default ChoseViewer;