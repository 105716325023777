import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import "./login.css";
import { imageUrls } from '../Helpers/images';
import Modal from './modal';
import LoginForm from './login-form';
import NewCvForm from './new-cv-form';
//import NewViewerForm from './new-viewer-form';
import CreatedCvConfirmation from './created-cv-confirmation';
import GettingStarted from './getting-started';

const Login = (props) => {
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [openNewCvModal, setOpenNewCvModal] = useState(false);
    const [openCreatedCvModal, setOpenCreatedCvModal] = useState(false);
    const [registerEmail, setRegisterEmail] = useState();
    const [registerFirstName, setRegisterFirstName] = useState();
    //const [openNewViewerModal, setOpenNewViewerModal] = useState(false);
    const user = useUser();

    return (
        <>
            {user.isLoggedIn ?
                props.children
                :
                <div id="loginContainer">
                    <Modal onClick={() => { setOpenLoginModal(false) }} openModal={ openLoginModal }>
                        <div className="text-white">
                            <LoginForm closeModal={() => { setOpenLoginModal(false) }} />
                        </div>
                    </Modal>
                    <Modal onClick={() => { setOpenNewCvModal(false) }} openModal={ openNewCvModal }>
                        <div className="text-white">
                            <NewCvForm setRegisterEmail={ setRegisterEmail } setRegisterFirstName={ setRegisterFirstName } closeModal={() => { setOpenNewCvModal(false); setOpenCreatedCvModal(true) }} />
                        </div>
                    </Modal>
                    <Modal background="bg-white" colorX="text-dark" onClick={() => { setOpenCreatedCvModal(false) }} openModal={ openCreatedCvModal }>
                        <div className="text-white">
                            <CreatedCvConfirmation registerFirstName= { registerFirstName } registerEmail={ registerEmail } closeModal={() => { setOpenCreatedCvModal(false) }} onClick={() => { setOpenLoginModal(true); setOpenCreatedCvModal(false) }} />
                        </div>
                    </Modal>
                    {/* <Modal onClick={() => { setOpenNewViewerModal(false) }} openModal={ openNewViewerModal }>
                        <div className="text-white">
                            <NewViewerForm closeModal={() => { setOpenNewViewerModal(false) }} />
                        </div>
                    </Modal> */}
                    <div id="welcome">Välkommen till</div>
                    <div id="logoContainer"><img id="logoImage" src={imageUrls.mycvLogo} /></div>
                    <div id="buttonsContainer">
                        <button onClick={() => { setOpenLoginModal(true) }} className="btn btn-success mx-2">
                            Logga in
                        </button>
                        <button onClick={() => { setOpenNewCvModal(true) }} className="btn btn-primary mx-2">
                            Skapa ett nytt cv
                        </button>
                        <button className="btn btn-secondary mx-2"
                            type="button" data-bs-toggle="collapse" data-bs-target="#aboutMyCv" aria-expanded="false" aria-controls="aboutMyCv">
                            Läs mer om hur MyCv fungerar
                        </button>
                        {/* <button onClick={ () => { setOpenNewViewerModal(true) }} className="btn btn-primary mx-2">
                            Registrera dig för att titta på cv
                        </button> */}
                    </div>
                    <div className="collapse" id="aboutMyCv">
                        <div id="myCvInfo" className="card card-body mx-2 my-2 py-2 px-5 d-inline-block">
                            <GettingStarted />
                        </div>
                    </div>
                    <div className='row mx-0'>
                        <div id="copyright" className='text-center'>
                            <h4>&copy; nbjörk 2022<img id="birch" src={imageUrls.birch} /></h4>
                            <button className="btn btn-sm btn-outline-secondary mx-2 my-2"
                                type="button" data-bs-toggle="collapse" data-bs-target="#aboutApp" aria-expanded="false" aria-controls="aboutApp">
                                Läs mer om webbapplikationen och hur den är uppbyggd
                            </button>
                            <div className="collapse py-3 px-5" id="aboutApp">
                                MyCv är en SPA-applikation och är byggd med hjälp av javascriptbiblioteket React. Byggsystem och transpilering hanteras av Parcel js och Babel.
                                Webbapplikationen jobbar mot ett REST-API byggt i ASP.NET Core. API:et är placerat på en Synology server och körs i en Docker bild (image).
                                Api:et sparar sin data i en MySQL-databas, som även den "snurrar" i en Docker-img på Synology servern.
                                Jag som gjort både api:et och webbapplikationen heter Niklas och studerar till Webbutvecklare.NET i Värnamo (Tekniska Högskolan i Jönköping) och har gjort appen både för att kunna visa mitt eget CV, 
                                men också för att visa de delar som jag lärt mig under mitt första studieår. 
                                Applikationen är fortfarande under utveckling och kommer uppdateras med både nya funktionen och refactoring av koden. Jag tar gärna emot synpunkter både på UX, UI och kodstruktur. Vill du kontakta mig så går det bra via e-post: mycv[a]nbjork.se.
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default Login;