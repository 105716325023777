import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import './padlock.css';
import { imageUrls } from '../Helpers/images';

const Padlock = (props) => {

    const user = useUser();
    const [padlock, setPadlock] = useState(
        user.allowedReferenceAndGrade || !user.isViewer ?
        imageUrls.padlockUnlockedGreen
        :
        imageUrls.padlockLockedRed     
    )

    return (
        <div id="padlock" className="d-inline-block" style={{ backgroundImage: `url(${padlock})` }}>
        </div>
    )
}
export default Padlock;