import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import urls from '../Model/fetch-url';
import useUser from './ContextAndHooks/user-context';
import PatchData from '../Model/patch-data';
import HideIfViewer from './hide-if-viewer';

const CivilStatus = (props) => {
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const user = useUser();

    const onButtonClick = (event) => {
        (async () => {
            const editedCivilStatus = { married: false }
            if (event.target.name === 'addMarriedBtn') {
                editedCivilStatus.married = true;
            }
            const [data, err] = await PatchData(urls.civilStatus, user.token, editedCivilStatus)
            setLoading(false);
            setErr(err);
            user.updateUser();
        })();
    }

    return (
        <>
            {user.user.cv.civilStatus
                ?
                user.user.cv.civilStatus.married
                    ?
                    <>
                        <dt className="col-sm-4">
                            Civilstånd:
                        </dt>
                        <dd className="col-sm-8">
                            Gift
                            <HideIfViewer>
                                <button onClick={(event) => { onButtonClick(event) }} id="removeMarriedBtn" name="removeMarriedBtn" type="button" className="btn btn-outline-danger btn-sm mx-3 py-0">
                                    Ta bort</button>
                            </HideIfViewer>
                        </dd>
                    </>
                    :
                    <HideIfViewer>
                        <dt className="col-sm-4">
                            Civilstånd:
                        </dt>
                        <dd className="col-sm-8">
                            Välj civilstånd:
                            <button onClick={(event) => { onButtonClick(event) }} name="addMarriedBtn" type="button" className="btn btn-primary btn-sm mx-3">
                                Gift
                            </button>
                        </dd>
                    </HideIfViewer>
                :
                null
            }
        </>
    )
}
export default CivilStatus;