const PostDataNoToken = async (url, model) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(model);
    
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${url}`, requestOptions);
            let data = response;
            return  [data, null]
        } catch(err) {
            return  [null, err]
        }
}
export default PostDataNoToken;
