import React, { useState, useEffect, useRef, } from 'react';
import './save-changes.css';
import useSave from './ContextAndHooks/save-context';
import useUser from './ContextAndHooks/user-context';
import urls from '../Model/fetch-url';
import patchData from '../Model/patch-data';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import GetUserData from '../Model/get-user-data';
import SaveBtn from './save-btn';

const SaveChanges = (props) => {
    const [visibility, setVisibility] = useState('hidden')
    const save = useSave();
    const user = useUser();
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();

    useEffect(() => {
        if(save.save && save.button === props.btnId)  {
            setVisibility('show');
        } else {
            setVisibility('hidden');
        }
    },[save.button])

    const saveEventHandler = (event) => {

        switch (save.savePlace) {
            case 'user':
                var userPosts = ['birthday', 'city', 'email', 'firstName', 'isAdmin', 'emailConfirmed', 'isViewer', 'lastName', 'nationality', 'phoneNumber', 'postalAddress', 'zipCode'];
                var editedUser = { ...user.user };

                var [userId] = save.userKey.split('/');
                userPosts.forEach(userPost => {
                    var result = JSON.parse(localStorage.getItem(`${userId}/${userPost}`));
                    if (result) {
                        editedUser[userPost] = result[0].children[0].text
                    }
                });

                (async () => {
                    const [data, err] = await patchData(urls.users + '/' + user.userId, user.token, editedUser)
                    setLoading(false);
                    setErr(err);
                    user.updateUser();
                    if (data) {
                        NotificationManager.success('Användardata uppdaterad!');
                    }
                    if (err) {
                        NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
                    }
                })();
                save.setSavePlace(null);
                save.setSave(false);
                save.setButton(null);
                save.setErr(false);
                save.setInEditMode(null);
            break;

            case 'cv':
                var cvPostsPlainText = ['gitHubUrl', 'gtiHubApi', 'linkedInUrl', 'drivingLicenseCode'];
                var cvPostsEditedText = ['personalLetter', 'shortPresentation'];
                var editedCv = { ...user.user.cv };
                var [cvId] = save.cvKey.split('/');

                cvPostsPlainText.forEach(cvPost => {
                    var result = JSON.parse(localStorage.getItem(`${cvId}/${cvPost}`));
                    if (result) {
                        editedCv[cvPost] = result[0].children[0].text;
                    }
                });
                cvPostsEditedText.forEach(cvPost => {
                    var result = localStorage.getItem(`${cvId}/${cvPost}`);
                    if (result) {
                        editedCv[cvPost] = result;
                    }
                });

                delete editedCv['civilStatus'];
                delete editedCv['cvId'];
                delete editedCv['educations'];
                delete editedCv['languages'];
                delete editedCv['userId'];
                delete editedCv['workExperiences'];

                (async () => {
                    const [data, err] = await patchData(urls.cv + '/' + user.user.cv.cvId, user.token, editedCv)
                    setLoading(false);
                    setErr(err);
                    user.updateUser();
                    if (data) {
                        NotificationManager.success('Information uppdaterad!');
                    }
                    if (err) {
                        NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
                    }
                })();
                save.setSavePlace(null);
                save.setSave(false);
                save.setButton(null);
                save.setErr(false);
                save.setInEditMode(null);
            break;

            case 'language':
                var [thisLanguageId] = save.languageKey.split('/');
                var editedLanguage = { ...user.user.cv.languages.find(l => l.languageId === thisLanguageId) }
                var result = JSON.parse(localStorage.getItem(save.languageKey));
                if (result) {
                    editedLanguage.thisLanguage = result[0].children[0].text;
                }

                (async () => {
                    const [data, err] = await patchData(urls.language+'/'+thisLanguageId, user.token, editedLanguage)
                    setLoading(false);
                    setErr(err);
                    user.updateUser();
                    if (data) {
                        NotificationManager.success('Språk uppdaterat!');
                    }
                    if (err) {
                        NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
                    }
                })();
                save.setSavePlace(null);
                save.setSave(false);
                save.setButton(null);
                save.setErr(false);
                save.setInEditMode(null);
            break;

            case 'education':
                var educationPostsPlainText = [
                    'educatorName',
                    'educationName',
                    'start',
                    'end',
                ];
                var educationPostsEditedText = [
                    'description',
                    'longDescription'
                ];
                var [educationId] = save.educationKey.split('/');
                var editedEducation = { ...user.user.cv.educations.find(l => l.educationId === educationId) }

                educationPostsPlainText.forEach(educationPost => {
                    var result = JSON.parse(localStorage.getItem(`${editedEducation.educationId}/${educationPost}`));
                    if (result) {
                        editedEducation[educationPost] = result[0].children[0].text;
                    }
                });
                educationPostsEditedText.forEach(educationPost => {
                    var result = localStorage.getItem(`${editedEducation.educationId}/${educationPost}`);
                    if (result) {
                        editedEducation[educationPost] = result;
                    }
                });

                delete editedEducation['cvId'];
                delete editedEducation['grade'];
                delete editedEducation['educationId'];

                (async () => {
                    const [data, err] = await patchData(urls.educations + '/' + educationId, user.token, editedEducation)
                    setLoading(false);
                    setErr(err);
                    user.updateUser();
                    if (data) {
                        NotificationManager.success('Utbildning uppdaterad!');
                    }
                    if (err) {
                        NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
                    }
                })();
                save.setSavePlace(null);
                save.setSave(false);
                save.setButton(null);
                save.setErr(false);
                save.setInEditMode(null);
            break;

            case 'workExperience':
                var workExperiencePostsPlainText = [
                    'company',
                    'end',
                    'formOfEmployment',
                    'start',
                    'title',
                    'end'];
                var workExperiencePostsEditedText = [
                    'workDescription',
                    'companyDescription'
                ];
                var [workExperienceId] = save.workExperienceKey.split('/');
                var editedWorkExperience = { ...user.user.cv.workExperiences.find(l => l.workExperienceId === workExperienceId) }

                workExperiencePostsPlainText.forEach(workExperiencePost => {
                    var result = JSON.parse(localStorage.getItem(`${editedWorkExperience.workExperienceId}/${workExperiencePost}`));
                    if (result) {
                        editedWorkExperience[workExperiencePost] = result[0].children[0].text;
                    }
                });
                workExperiencePostsEditedText.forEach(workExperiencePost => {
                    var result = localStorage.getItem(`${editedWorkExperience.workExperienceId}/${workExperiencePost}`);
                    if (result) {
                        editedWorkExperience[workExperiencePost] = result;
                    }
                });

                delete editedWorkExperience['cvId'];
                delete editedWorkExperience['workCertificates'];
                delete editedWorkExperience['workExperienceId'];

                (async () => {
                    const [data, err] = await patchData(urls.workExperience + '/' + workExperienceId, user.token, editedWorkExperience)
                    setLoading(false);
                    setErr(err);
                    user.updateUser();
                    if (data) {
                        NotificationManager.success('Arbetslivsfarenhet uppdaterad!');
                    }
                    if (err) {
                        NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
                    }
                })();
                save.setSavePlace(null);
                save.setSave(false);
                save.setButton(null);
                save.setErr(false);
                save.setInEditMode(null);
            break;

            case 'grade':
                var gradePostsPlainText = [
                    'examineGrade',
                    'discription',
                ];

                var [gradeId] = save.gradeKey.split('/');
                (async () => {
                    const [data, err] = await GetUserData(urls.grade + '/' + gradeId, user.token)
                    setLoading(false);
                    setErr(err);
                    editedGrade = data;

                    gradePostsPlainText.forEach(gradePost => {
                        var result = JSON.parse(localStorage.getItem(`${editedGrade.gradeId}/${gradePost}`));
                        if (result) {
                            editedGrade[gradePost] = result[0].children[0].text;
                        }
                    });

                    delete editedGrade['gradeId'];
                    delete editedGrade['educationId'];
                    delete editedGrade['appendices'];

                    (async () => {
                        const [data, err] = await patchData(urls.grade + '/' + gradeId, user.token, editedGrade)
                        setLoading(false);
                        setErr(err);
                        user.updateUser();
                        if (data) {
                            NotificationManager.success('Betyg uppdaterat!');
                        }
                        if (err) {
                            NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
                        }
                    })();
                })();
                save.setSavePlace(null);
                save.setSave(false);
                save.setButton(null);
                save.setErr(false);
                save.setInEditMode(null);
            break;
        }
    }

    return (
        <SaveBtn onClick={ saveEventHandler } class={ visibility } btnId={ props.btnId }/>
        // <div id="saveBar" onClick={() => { saveEventHandler() }} className="alert alert-warning" role="alert">
        //     Click to save changes in document.
        // </div>
    );
}
export default SaveChanges;