import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import { useForm } from "react-hook-form";
import urls from '../Model/fetch-url';
import { NotificationManager } from 'react-notifications';
import PatchData from '../Model/patch-data';
import GetUserData from '../Model/get-user-data';

const GitHubForm = (props) => {
    const user = useUser();
    const [gitHubNamePlaceholder, setGitHubNamePlaceholder] = useState('');
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const [loginError, setLoginError] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        if (props.gitHubApiUrl) {
            var gitHubName = props.gitHubApiUrl.split('/');
            setGitHubNamePlaceholder(gitHubName[4]);
        }
    },[])

    const gitHubNameonChange = (e) => {
        setGitHubNamePlaceholder(e.target.value);
    }

    const onSubmit = (submitData) => {
        submitData.gitHubApi = (submitData.gitHubApi === "true");
        submitData.gitHubApiUrl = `https://api.github.com/users/${submitData.gitHubApiUrl}/starred`;
        (async () => {
            const [data, err] = await GetUserData(urls.cv + '/' + user.user.cv.cvId, user.token)
            setLoading(false);
            setErr(err);
            user.updateUser();
            if (data) {
                var cvToEdit = data;
                cvToEdit.gitHubApi = submitData.gitHubApi;
                cvToEdit.gitHubApiUrl = submitData.gitHubApiUrl;
            }
            if (err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }

            (async () => {
                const [data, err] = await PatchData(urls.cv + '/' + user.user.cv.cvId, user.token, cvToEdit)
                setLoading(false);
                setErr(err);
                user.updateUser();
                if (data) {
                    NotificationManager.success('GitHub repos ändrad!');
                }
                if (err) {
                    NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
                }
            })();
        })();
        props.setGitHubApi(submitData.gitHubApi);
        props.setGitHubApiUrl(submitData.gitHubApiUrl);
        props.closeModal();
    };

    const changeValue = (e) => {
        if (e.target.value === 'false') {
            props.setGitHubApi(true);
        }
        else {
            props.setGitHubApi(false);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label>Användarnamn på GitHub<br />
                <input
                    {...register("gitHubApiUrl", {
                        required: "Klicka i fältet för att aktivera befintlig text, eller skriv en ny användare"
                    })}
                    type="text"
                    value={ gitHubNamePlaceholder }
                    onChange={(e) => { gitHubNameonChange(e) }}
                />
            </label>
            <div>
                <small className='error text-danger'>{errors.gitHubApiUrl?.message}</small>
            </div>

            <label>Aktivera GitHub repos:
                <input
                    {...register("gitHubApi")}
                    type="checkbox"
                    checked={ props.gitHubApi }
                    value={ props.gitHubApi }
                    id='gitHub'
                    onClick={(e) => { changeValue(e) }}
                />
            </label>
            <br />

            <div id="btnContainer">
                <button type="submit" className="btn btn-primary btn-sm mx-2">Spara</button>
            </div>
        </form>
    )
}
export default GitHubForm;