import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import urls from '../Model/fetch-url';
import useUser from './ContextAndHooks/user-context';
import patchData from '../Model/patch-data';
import './skill-graph.css';

const SkillGraph = (props) => {

    const cursor = () => {
        if(!user.isViewer){
            return {cursor: 'pointer'}
        }
    }
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const user = useUser();
    const [styleOne, setStyleOne] = useState(cursor());
    const [styleTwo, setStyleTwo] = useState(cursor());
    const [styleThree, setStyleThree] = useState(cursor());
    const [styleFour, setStyleFour] = useState(cursor());
    const [styleFive, setStyleFive] = useState(cursor());

    const resetState = () => {
        setStyleOne(cursor());
        setStyleTwo(cursor());
        setStyleThree(cursor());
        setStyleFour(cursor());
        setStyleFive(cursor());
      };

    const onClickHandler = (event, value) => {
        const fetchedLanguage = user.user.cv.languages.find(l => l.languageId === props.languageId);
        let editedLanguage = {...fetchedLanguage};
        editedLanguage[props.skill] = value;

        if(!user.isViewer) {
            (async () => {
                const [data, err] = await patchData(urls.language +'/'+ props.languageId, user.token, editedLanguage)
                setLoading(false);
                setErr(err);
                user.updateUser();
            })();
        }
        if(!user.isViewer) {
            resetState();
        }
    }

    useEffect(() => {
        const style = {
            outline: '1px solid black',
            outlineOffset: '2px'
        }
        switch (props.level) {
            case 1:
                setStyleOne(style);
                break;
            case 2:
                setStyleTwo(style);
                break;
            case 3:
                setStyleThree(style);
                break;
            case 4:
                setStyleFour(style);
                break;
            case 5:
                setStyleFive(style);
                break;
        }
    }, [user.user])

    return (
        <div id="graphHolder"><small id="label">{props.label}</small>
            <div id="one" onClick={(event) => {onClickHandler(event, 1)}} style={styleOne} className="graph"></div>
            <div id="two" onClick={(event) => {onClickHandler(event, 2)}} style={styleTwo} className="graph"></div>
            <div id="three" onClick={(event) => {onClickHandler(event, 3)}} style={styleThree} className="graph"></div>
            <div id="four" onClick={(event) => {onClickHandler(event, 4)}} style={styleFour} className="graph"></div>
            <div id="five" onClick={(event) => {onClickHandler(event, 5)}} style={styleFive} className="graph"></div>
        </div>
    )
}
export default SkillGraph;