import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import { useForm } from "react-hook-form";
import urls from '../Model/fetch-url';
import "./login-form.css";
import PostAuthenticate from '../Model/post-authenticate';

const LoginForm = (props) => {
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const [loginError, setLoginError] = useState(false);
    const [emailConfirmed, setEmailConfirmed] = useState(true);
    const user = useUser();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (submitData) => {
        (async () => {
            const [data, err] = await PostAuthenticate(urls.authenticate, submitData)
            setLoading(false);
            setErr(err);
            if(data) {
                if(data.emailConfirmed) {
                    user.setToken(data.token);
                    if(data.isViewer === true) {
                        user.setUserId(data.viewId);
                        user.setIsViewer(true);
                        user.setAllowedReferenceAndGrade(data.viewAllowedReferenceAndGrade)
                    } else {
                        user.setUserId(data.id);
                    }
                    user.updateUser();
                    user.setIsLoggedIn(true);
                    setLoginError(false);
                    props.closeModal();
                } else {
                    setEmailConfirmed(false);
                }
            } else {
                setLoginError(true);
            }

        })();
        
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label>E-post<br/>
                        <input  
                            {...register("email", {
                                required: "Fältet är obligatoriskt"
                            })} 
                            type="email"
                        />
                    </label>
                    <div>
                        <small className='error text-danger'>{errors.email?.message}</small>
                    </div>

            <label>Lösenord<br/>
                        <input  
                            {...register("password", {
                                required: "Fältet är obligatoriskt"
                            })} 
                            type="password"
                        />
                    </label>
                    <div>
                        <small className='error text-danger'>{errors.password?.message}</small>
                    </div>

            <div id="btnContainer">
                <button type="submit" className="btn btn-primary btn-sm mx-2">Logga in</button>
            </div>
            {loginError?
            <p id="loginError" className='text-danger'>Fel användarnamn eller lösenord</p>
            :
            null}
            {!emailConfirmed?
            <p id="loginError" className='text-danger'>E-postadressen är inte bekräftad. Klicka på länken i bekräftelsemailet för verifiera din registrerade e-post.</p>
            :
            null}
        </form>
    )
}
export default LoginForm;