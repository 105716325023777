import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import "./created-cv-confirmation.css";

import './contact.css';
import urls from '../Model/fetch-url';
import PostDataNoToken from '../Model/post-data-no-token';

const CreatedCvConfirmation = (props) => {

    const user = useUser();
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const [newMailSent, setNewMailSent] = useState('');

    const sendRegisterEmail = async () => {
        setNewMailSent('');
        var model = {
            toEmail: props.registerEmail,
            name: props.registerFirstName
        }

        const [data, err] = await PostDataNoToken(urls.welcomeMail, model)
        if(data){
            if (data.status === 200) {
                setNewMailSent(true);
            } else {
                setNewMailSent(false);
            }
        } else setNewMailSent(false);
        
        setLoading(false);
        setErr(err);
    }

    return (
        <>
            <div id="confHeader" >Grattis!</div>
            <div id="text" >Du är nu på god väg att skapa ditt egna digitala CV.
                Fortsätt med att logga in. Redigera sedan dina personliga uppgifter, lägg till utbildningar, arbetslivserfarenheter och arbetsprover.
                Skapa sedan besökare till ditt CV och dela med potentiella arbetsgivare.<br />
                <strong>Välkommen och lycka till!</strong>
            </div>
            <button onClick={() => { props.onClick() }} className="btn btn-primary mx-2 my-2">Logga in</button>
            <p className="border-top border-dark my-3 row"></p>
            <button onClick={() => { sendRegisterEmail() }} className="btn btn-info mx-2 my-2">Skicka registreringsmail igen</button>
            <br />
            {newMailSent === '' ?
                null
                :
                newMailSent?
                    <small className='mx-2 text-success'>Nytt verifieringsmail skickat!</small>
                    :
                    <small className='mx-2 text-danger'>Kunde inte skicka e-post</small>
            }
            <small className='text-warning d-block my-2 mx-2'>Glöm inte att verifiera din e-post genom att klicka på länken i verifieringsmailet, innan du försöker logga in. Hittar du inget mail, prova att skicka igen med knappen ovan, eller titta bland din skräppost om mailet hamnat där.</small>
        </>
    )
}
export default CreatedCvConfirmation;