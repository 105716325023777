////@ts-check
import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { createEditor, Editor, Transforms, Text } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { CustomEditor, CodeElement, DefaultElement, Leaf } from './Editor/custom-editor';
import useUser from './ContextAndHooks/user-context';
import useSave from './ContextAndHooks/save-context';
import useEditable from './ContextAndHooks/editable-context';
import { useStorageState } from 'react-storage-hooks';

const EditText = (props) => {
    const user = useUser();
    const save = useSave();
    const editable = useEditable();
    const jsonString = () => {
        if(props.thisText) {
            try {
                JSON.parse(props.thisText)
            } catch {
                return false;
            }
            return true;
        } else return false;
    }

    const start = () => {
        if (!jsonString()) {
            return [
                {
                    type: 'paragraph',
                    _children: [{ text: !props.thisText? props.initialText : props.thisText }],
                    get children() {
                        return this._children;
                    },
                    set children(value) {
                        this._children = value;
                    },
                }
            ]
        } else {
            return JSON.parse(props.thisText);
        }
    }

    const [initialValue, setInitialValue] = useStorageState(localStorage, `${props.thisId}/${props.thisKey}`, start());

    const [editor] = useState(() => withReact(createEditor()))

    const renderElement = useCallback(({ attributes, children, element }) => {
        switch (element.type) {
          case 'quote':
            return <blockquote {...attributes}>{children}</blockquote>
          case 'link':
            return (
              <a {...attributes} href={element.url}>
                {children}
              </a>
            )
          default:
            return <div {...attributes}>{children}</div>
        }
      }, [])

    const renderLeaf = useCallback(props => {
        return <Leaf {...props} />
    }, [])

    const checkThisReadOnly = () => {
        if (save.inEditMode && save.inEditMode !== props.thisId+'/'+props.thisKey) {
            return true
        } else {
            return false
        }
    }

    return (
        <div id={props.cvKey} className="lh-base">
            <Slate
                editor={editor}
                value={initialValue}
                onChange={value => {
                    const isAstChange = editor.operations.some(
                        op => 'set_selection' !== op.type
                    )
                    if (!isAstChange) {
                        if(save.inEditMode && save.inEditMode !== props.thisId+'/'+props.thisKey) {
                            save.setErr(true);
                        }
                    }
                    if (isAstChange) {
                        save.setInEditMode(props.thisId+'/'+props.thisKey);
                        setInitialValue(value);
                        save.setSave(true);
                        save.setButton(props.thisId+'/'+props.thisKey);
                        save.setSavePlace(props.savePlace);
                        props.setSaveContextKey(props.thisId+'/'+props.thisKey);
                    }
                }}
            >
                <Editable
                    editor={editor}
                    readOnly={user.isViewer || checkThisReadOnly() || editable.preview}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    onKeyDown={event => {
                        if (!event.ctrlKey) {
                            return
                        }

                        switch (event.key) {
                            case 'i': {
                                event.preventDefault()
                                CustomEditor.toggleItalicMark(editor)
                                break
                            }

                            case 'b': {
                                event.preventDefault()
                                CustomEditor.toggleBoldMark(editor)
                                break
                            }
                        }
                    }}
                />
            </Slate>
        </div>
    )
}
export default EditText;