import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import HeaderImages from './header-images';
import useUser from './ContextAndHooks/user-context';
import useSave from './ContextAndHooks/save-context';
import EditText from './edit-text';
import './start.css';
import SaveChanges from './save-changes';
import useEditable from './ContextAndHooks/editable-context';
import { imageUrls } from '../Helpers/images';
import BlinkingEyeBtn from '../BlinkingEye/blinking-eye-btn';

const Start = (props) => {
    const user = useUser();
    const save = useSave();
    const editable = useEditable();
    return (
        <>
            <div className="col">
                <div className="row rightSide">
                    <HeaderImages />
                    <div className="row gx-5">
                        <div id="introText" className="col-sm text">
                            <div id="shortP" className={editable.editable}>
                                <EditText initialText='En kort och spännande beskrivning av dig som person. Likt en rubrik som ska locka till mer läsning.' setSaveContextKey={save.setCvKey} savePlace="cv" thisKey='shortPresentation' thisId={user.user.cv.cvId} thisText={user.user.cv.shortPresentation} />
                            </div>
                            <div id="saveBtn" className='row' >
                            <SaveChanges btnId={user.user.cv.cvId + '/shortPresentation'} />
                            </div>
                            <div>
                                <a className="btn btn-primary py-0" data-bs-toggle="collapse" href="#aboutMe" role="button"
                                    aria-expanded="false" aria-controls="aboutMe">
                                    <BlinkingEyeBtn type='local' imageActivated={imageUrls.contractWhite} imageDeactivated={imageUrls.expandWhite} handleEvent={false} id="smallBtn" name="readMore" setStatus={false} text={`Läs mer om ${user.user.firstName} ${user.user.lastName}`} />
                                </a>
                            </div>
                            <div className="collapse" id="aboutMe">
                                <div id="personalL" className={`card card-body my-3 ${editable.editable}`}>
                                    <EditText initialText='En liten längre text som beskriver dig som person. Intressen, personligheter m.m.' setSaveContextKey={save.setCvKey} savePlace="cv" thisKey='personalLetter' thisId={user.user.cv.cvId} thisText={user.user.cv.personalLetter} />
                                    <div id="saveBtnCollapse" className='row'>
                                        <SaveChanges btnId={user.user.cv.cvId + '/personalLetter'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Start;