import React, { useState, useEffect, useRef, } from 'react';
import './header-images.css';
import { imageUrls } from '../Helpers/images';

const HeaderImages = (props) => {


    return (
        <div id="imageHeader" className="col">
            <div id="imageHolder" className="row">
                <div className="imageContainer col" style={{ backgroundImage: `url(${imageUrls.stark})` }}>Stark</div>
                <div className="imageContainer col" style={{ backgroundImage: `url(${imageUrls.effektiv})` }}>Effektiv</div>
                <div className="imageContainer col" style={{ backgroundImage: `url(${imageUrls.hjalpsam})` }}>Hjälpsam</div>
                <div className="imageContainer col" style={{ backgroundImage: `url(${imageUrls.gransoverskridande})` }}>Gränsöver-skridande<br /></div>
                <div className="imageContainer col" style={{ backgroundImage: `url(${imageUrls.lagspelare})` }}>Lag-<br />spelare</div>
            </div>

        </div>
    );
}
export default HeaderImages