import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import urls from '../Model/fetch-url';
import useUser from './ContextAndHooks/user-context';
import useSave from './ContextAndHooks/save-context';
import PatchData from '../Model/patch-data';
import HideIfViewer from './hide-if-viewer';
import useEditable from './ContextAndHooks/editable-context';
import SaveChanges from './save-changes';
import EditInfo from './edit-info';

const DrivingLicenseCode = (props) => {
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const user = useUser();
    const save = useSave();
    const editable = useEditable();

    const onButtonClick = (event) => {

        if (event.target.name === 'addDrivingLicenseCode') {
            var cvObject = { ...user.user.cv };
            cvObject.drivingLicenseCode = 'XX/XX/XX';
        } else {
            var cvObject = { ...user.user.cv };
            cvObject.drivingLicenseCode = null;
        }

        (async () => {
            const [data, err] = await PatchData(urls.cv+'/'+user.user.cv.cvId, user.token, cvObject)
            setLoading(false);
            setErr(err);
            user.updateUser();
        })();

    }

    return (
        <>
            {user.user.cv.drivingLicenseCode
                ?
                <>
                    <dt className="col-sm-4">
                        Körkortsbehörigheter:
                    </dt>
                    <dd className="col-sm-8">
                        <div className={`d-inline-block ${editable.editable}`}>
                            <EditInfo initialText='Fordon du får köra' setSaveContextKey={save.setCvKey} thisKey='drivingLicenseCode' savePlace="cv" thisId={user.user.cv.cvId} thisInfo={ user.user.cv.drivingLicenseCode } />
                        </div>
                        <SaveChanges btnId={user.user.cv.cvId + '/drivingLicenseCode'} />
                        <HideIfViewer>
                            <button onClick={(event) => { onButtonClick(event) }} id="removeDrivingLicenseCode" name="removeDrivingLicenseCodeBtn" type="button" className="btn btn-outline-danger btn-sm mx-3 py-0">
                                Ta bort</button>
                        </HideIfViewer>
                    </dd>
                </>
                :
                <HideIfViewer>
                    <dt className="col-sm-4">
                        Körkortsbehörigheter:
                    </dt>
                    <dd className="col-sm-8">
                        <span className='text-muted'>Ex. AM/A1/A2/A/B</span>
                        <button onClick={(event) => { onButtonClick(event) }} name="addDrivingLicenseCode" type="button" className="btn btn-primary btn-sm mx-3">
                            Lägg till körkortsbehörigheter
                        </button>
                    </dd>
                </HideIfViewer>
            }
        </>
    )
}
export default DrivingLicenseCode;