const PostImage = async (url, token, file, fileName, key) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append(key, file, fileName);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${url}`, requestOptions);
        let data = await response.json();
        return [data, null]
    } catch (err) {
        return [null, err]
    }
}
export default PostImage;
