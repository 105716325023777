import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { imageUrls } from '../Helpers/images';
import "./getting-started.css";

const GettingStarted = (props) => {

    return (
        <div id="gettingStarted">
            <div className='row my-5'>
                <div className='col-sm-6 text-center'>
                    <img className='gettingStartedImages' src={imageUrls.gettingStartedLogin} />
                </div>
                <div className='col-sm-6'>
                    <h2>Registrering</h2>
                    MyCv är en applikation där du kan skapa ditt egna digitala CV samt en beskrivning av dig som person.
                    Fördelen med ett digitalt CV är att det alltid är uppdaterat för företagen som tittar på det och du har all information samlat på ett ställe.
                    Du kan skapa flera olika besökare för dem du vill ska ha åtkomst till CV:t och även radera besökare som kanske inte längre är aktuella.
                    Du lägger enkelt till utbildningar, arbetslivserfarenheter och redigerar texter där de visas.
                    <br />
                    Nu tycker jag vi kör igång! Börja med att skapa ett konto.

                </div>
            </div>

            <p className="border-top border-dark my-3 row"></p>
            <div className='row my-5 '>
                <div className='col-sm-6'>
                    <img className='gettingStartedImages' src={imageUrls.gettingStartedCvStart} />
                </div>
                <div className='col-sm-6'>
                    <h2>Startsidan</h2>
                    När du skapat ett konto kan du direkt börja redigera ditt CV.
                    På vänster sida har du en meny med kategorier för ditt CV.
                    Sidan startar alltid med att visa information om dig som person.
                    Det första besökaren ser är en inledande text om dig som person, och tanken är att den ska fånga besökarens uppmärksamhet.
                    Under den texten finns det sedan en knapp som visar ytterligare information. Här är tanken att ditt personliga brev ska skrivas.
                    Framöver kommer det gå att ändra bilder och text eller ta bort "headern" i ovankant.
                </div>
            </div>

            <p className="border-top border-dark my-3 row"></p>
            <div className='row my-5 '>
                <div className='col-sm-6'>
                    <img className='gettingStartedImages' src={imageUrls.gettingStartedEditing} />
                </div>
                <div className='col-sm-6'>
                    <h2>Redigering</h2>
                    Att ändra din information och texterna i CV: är enkelt. Du klickar på texten och tar bort eller skriver.
                    Vid redigering kommer då en gul spara-knapp upp som du klickar på när du redigerat klart texten.
                    Längre text kan du radbryta, och göra <strong>bold</strong> eller <i>kursiv</i>.
                    Bryter rad gör du med [enter] och för att göra texten kursiv eller bold, håller du nerer [control] och ändrar fram och tillbaka med antingen [i] för kursiv eller [b] för bold.
                </div>
            </div>

            <p className="border-top border-dark my-3 row"></p>
            <div className='row my-5 '>
                <div className='col-sm-6'>
                    <img className='gettingStartedImages' src={imageUrls.gettingStartedEditButtons} />
                </div>
                <div className='col-sm-6'>
                    <h2>Redigeringsknappar</h2>
                    Längst ner i sidomenyn finns det några knappar som hjälp i redigeringen och för att hantera besökare.
                    Eftersom inte alla redigeringsknappar ska synas när en besökare är inne och tittar på ditt CV, kan du förhandskolla hur det kommer att se ut genom att klicka på "Förhandsgranska".
                    Är det svårt att veta viken text som kan redigeras, så kan du klicka på den gula knappen, och all text som kan redigeras blir ljusgrön.
                    Genom att återigen klicka på knapparna återställs redigeringsknapar eller markering av text försvinner.
                    Med besökarknapparna kan du skapa inloggning för besökare till ditt CV, redigera eller ta bort.
                </div>
            </div>

            <p className="border-top border-dark my-3 row"></p>
            <div className='row my-5 '>
                <div className='col-sm-6'>
                    <img className='gettingStartedImages' src={imageUrls.gettingStartedProfileImage} />
                </div>
                <div className='col-sm-6'>
                    <h2>Namn och profilbild</h2>
                    Genom att klicka på redigeringssymbolen där din profilbild ska vara, kan du välja bild eller ta bort bild.
                    Du kan också ändra ditt förnamn och efternamn genom att klicka på namnet och ändra.
                </div>
            </div>

            <p className="border-top border-dark my-3 row"></p>
            <div className='row my-5 '>
                <div className='col-sm-6'>
                    <img className='gettingStartedImages' src={imageUrls.gettingStartedEducation} />
                </div>
                <div className='col-sm-6'>
                    <h2>Utbildningar</h2>
                    Att lägga till utbildningar eller arbetslivserfarenheter är väldigt lika.
                    I menyn under erfarenheter väljer du Arbetslivserfarenhet eller Utbildningar och kurser.
                    För att lägga till en utbildning klickar du på knappen högst upp och en utbildningspost skapas.
                    Ändra sedan informationen genom att klicka på texten och skriva.
                    För utbildning går det också att lägga till webbsida för utbildningen,
                    ett slutbetyg och när man lagt till ett slutbetyg går det ladda upp bilder eller pdf:er för att verifiera att det är rätt betyg.
                </div>
            </div>

            <p className="border-top border-dark my-3 row"></p>
            <div className='row my-5 '>
                <div className='col-sm-6'>
                    <img className='gettingStartedImages' src={imageUrls.gettingStartedWorkExperience} />
                </div>
                <div className='col-sm-6'>
                    <h2>Arbetslivserfarenhet</h2>
                    För att lägga till arbetslivserfarenhet gör du på samma sätt som med utbildning.
                    Här kan du också lägga till webbsida till företaget, beskriva både företaget och din yrkesroll samt ladda upp bilagor för t.ex. betyg eller arbetsintyg.
                    Både utbildningar och arbetslivserfarenheter sorteras efter tidsperiod. Det senaste kommer längst upp.
                    Tänk på det när du redigerar, för så fort datumet är ändrat flyttar sig posten.
                </div>
            </div>

            <p className="border-top border-dark my-3 row"></p>
            <div className='row my-5 text-center'>
                <h2>Kort genomgång</h2>
                <div>
                Detta var en kort genomgång av applikationen och jag hoppas att det hjälper dig att komma igång.
                <br />Du får mycket gärna höra av dig med förslag på utveckling, buggar eller utseendeförändringar.
                <br /><strong>Du når mig via e-post mycv[a]nbjork.se</strong>
                </div>
            </div>
            <p className="border-top border-dark my-3 row"></p>
        </div>
    )
}
export default GettingStarted;