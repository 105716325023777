import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import urls from '../Model/fetch-url';
import useUser from './ContextAndHooks/user-context';
import HeaderImages from './header-images';
import DeleteData from '../Model/delete-data';
import HideIfViewer from './hide-if-viewer';
import './educations.css';
import { imageUrls } from '../Helpers/images';
import Modal from './modal';
import NewGradeAppendixForm from './new-grade-appendix-form';
import NewEducationWebbSiteForm from './new-education-webb-site-form';
import GetUserData from '../Model/get-user-data';
import PatchData from '../Model/patch-data';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import EditInfo from './edit-info';
import EditText from './edit-text';
import PostData from '../Model/post-data';
import useSave from './ContextAndHooks/save-context';
import SaveChanges from './save-changes';
import useEditable from './ContextAndHooks/editable-context';
import BlinkingEyeBtn from '../BlinkingEye/blinking-eye-btn';
import Padlock from './padlock';
import ChangeDate from './change-date';

const Educations = (props) => {

    const user = useUser();
    const save = useSave();
    const editable = useEditable();
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const [openNewGradeAppendix, setOpenNewGradeAppendix] = useState(false);
    const [openNewWebbSite, setOpenNewWebbSite] = useState(false);
    const [activeEducationId, setActiveEducationId] = useState(null);
    const [activeGradeId, setActiveGradeId] = useState(null);

    const handleAddEducation = () => {
        let [today] = new Date().toISOString().split('T');

        var newEducation = {
            educatorName: "Lärosätets namn",
            educationName: "Utbildningens namn",
            course: false,
            start: today,
            end: today,
            description: "Kort beskrivning av utbildningen",
            longDescription: "Utbildningens innehåll"
        };

        (async () => {
            const [data, err] = await PostData(urls.educations, user.token, newEducation)
            setLoading(false);
            setErr(err);
            user.updateUser();
            if (data) {
                NotificationManager.success('Ny utbildning tillagd!');
            }
            if (err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }
        })();
    }

    const removeEducationButtonClick = (e) => {
        (async () => {
            const [data, err] = await DeleteData(urls.educations, user.token, e.target.getAttribute('educationid'),)
            setLoading(false);
            setErr(err);
            user.updateUser();
            if (data) {
                NotificationManager.info('Utbildning borttagen!');
            }
            if (err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }
        })();
    }

    const sortEducationList = () => {
        let sortedList = user.user.cv.educations.sort((a, b) => {
            let da = new Date(a.start),
                db = new Date(b.start);
            return db - da;
        });
        return sortedList;
    };

    const openAppendix = (e) => {
        if(user.allowedReferenceAndGrade || !user.isViewer) {
            window.open(urls.rootApi + "/" + e.target.getAttribute('appendixurl'), '_blank', 'fullscreen=yes')
        }
        else {
            NotificationManager.error('Vill du se bilagan kontakta personen som skrivit cv:t för upplåsning.', 'Bilagan är låst!', 5000);
        }
    }

    const removeThisBtn = (e) => {

        (async () => {
            const [data, err] = await DeleteData(urls.grade + '/appendix/' + e.target.getAttribute('gradeid'), user.token, e.target.getAttribute('appendixid'))
            setLoading(false);
            setErr(err);
            user.updateUser();
            if (data) {
                NotificationManager.success('Bilaga borttagen!');
            }
            if (err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }
        })();
    }

    const setEducationId = (educationId) => {
        setActiveEducationId(educationId);
    }

    const getEducationDataToEdit = async (id) => {

        const [data, err] = await GetUserData(urls.educations + '/' + id, user.token)
        setLoading(false);
        setErr(err);
        var educationToEdit = data;
        delete educationToEdit['cvId'];
        delete educationToEdit['educationId'];
        delete educationToEdit['grade'];

        return educationToEdit
    }

    const removeWebbUrlButtonClick = async (e) => {
        var editedEducation = await getEducationDataToEdit(e.target.getAttribute('educationId'));
        editedEducation.webbSiteUrl = null;

        (async () => {
            const [data, err] = await PatchData(urls.educations + '/' + e.target.getAttribute('educationId'), user.token, editedEducation)
            setLoading(false);
            setErr(err);
            user.updateUser();
            if (data) {
                NotificationManager.info('Webbadressen borttagen!');
            }
            if (err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }
        })();
    }

    const changeEducationForm = async (e) => {

        var editedEducation = await getEducationDataToEdit(e.target.getAttribute('educationId'));
        editedEducation.course = !editedEducation.course;
        (async () => {
            const [data, err] = await PatchData(urls.educations + '/' + e.target.getAttribute('educationId'), user.token, editedEducation)
            setLoading(false);
            setErr(err);
            user.updateUser();
            if (data) {
                NotificationManager.success('Utbildning ändrad!');
            }
            if (err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }
        })();
    }

    const createNewExamineGrade = (e) => {
        var newGrade = {
            examineGrade: "Skriv slutbetyg här",
            discription: "Beskrivning av betyget"
        };
        (async () => {
            const [data, err] = await PostData(urls.grade + '/' + e.target.getAttribute('educationId'), user.token, newGrade)
            setLoading(false);
            setErr(err);
            user.updateUser();
            if (data) {
                NotificationManager.success('Nytt slutbetyg tillagt!');
            }
            if (err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }
        })();
    }

    const removeGradeButtonClick = (e) => {

        (async () => {
            const [data, err] = await DeleteData(urls.grade, user.token, e.target.getAttribute('gradeid'))
            setLoading(false);
            setErr(err);
            user.updateUser();
            if (data) {
                NotificationManager.success('Slutbetyg borttaget!');
            }
            if (err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }
        })();
    }

    return (
        <>
            <Modal onClick={() => { setOpenNewGradeAppendix(false); setActiveGradeId(null) }} openModal={openNewGradeAppendix}>
                <div className="text-white">
                    <NewGradeAppendixForm activeGradeId={activeGradeId} closeModal={() => { setOpenNewGradeAppendix(false); setActiveGradeId(null) }} />
                </div>
            </Modal>
            <Modal onClick={() => { setOpenNewWebbSite(false); setActiveEducationId(null) }} openModal={openNewWebbSite}>
                <div className="text-white">
                    <NewEducationWebbSiteForm activeEducationId={activeEducationId} closeModal={() => { setOpenNewWebbSite(false); setActiveEducationId(null) }} />
                </div>
            </Modal>
            <div className="col">
                <div className="row rightSide">
                    <HeaderImages />
                    <div className="row gx-0">
                        <div id="infoText" className="col-sm text">
                            <HideIfViewer>
                                <div id="editSpace" className="col rounded">
                                    <button type="button" onClick={handleAddEducation} className="btn btn-primary btn-sm">
                                        Lägg till utbildning
                                    </button>
                                </div>
                            </HideIfViewer>
                            <h1>Utbildningar</h1>
                            <div className="border-top border-dark my-3 row"></div>
                            {sortEducationList().map((education) =>
                            (
                                <div id="educationCard" key={education.educationId}>
                                    <dl className="row">
                                        <dt className="col-sm-4">Tidsperiod:</dt>
                                        <dd className="col-sm-8 fw-light">
                                            <ChangeDate fetchObjectById={true} apiUrl={urls.educations} objectId={ education.educationId } startDate={education.start} objectKey={'start'} />
                                            <span id="space"></span>
                                            <span className='fst-italic'>till</span>
                                            <span id="space"></span>
                                            <ChangeDate fetchObjectById={true} apiUrl={urls.educations} objectId={ education.educationId } startDate={education.end} objectKey={'end'} />

                                        </dd>
                                        {education.course ?
                                            <dt className="col-sm-4">Utbildare:</dt>
                                            :
                                            <dt className="col-sm-4">Lärosäte:</dt>
                                        }
                                        <dd className="col-sm-8 fw-light fst-italic">
                                            <div className={`d-inline-block ${editable.editable}`}>
                                                <EditInfo initialText='Namnet på skolan' setSaveContextKey={save.setEducationKey} thisKey='educatorName' savePlace="education" thisId={education.educationId} thisInfo={education.educatorName} />
                                            </div>
                                            <SaveChanges btnId={education.educationId + '/educatorName'} />
                                        </dd>
                                        {education.course ?
                                            <dt className="col-sm-4">Kurs:</dt>
                                            :
                                            <dt className="col-sm-4">Utbildning:</dt>
                                        }
                                        <dd className="col-sm-8 fw-light fst-italic">
                                            <div className={`d-inline-block ${editable.editable}`}>
                                                <EditInfo initialText='Namnet på utbildningen' setSaveContextKey={save.setEducationKey} thisKey='educationName' savePlace="education" thisId={education.educationId} thisInfo={education.educationName} />
                                            </div>
                                            <SaveChanges btnId={education.educationId + '/educationName'} />
                                        </dd>
                                        {!education.webbSiteUrl
                                            ?
                                            <HideIfViewer>
                                                <dt className="col-sm-4">Utbildningens webbsida:</dt>
                                                <dd className="col-sm-8">
                                                    <button onClick={(event) => { setOpenNewWebbSite(true); setEducationId(education.educationId) }} name="addWebbUrl" type="button" className="btn btn-primary btn-sm">
                                                        Lägg till webbsida
                                                    </button>
                                                </dd>
                                            </HideIfViewer>
                                            :
                                            <>
                                                <dt className="col-sm-4">Webbsida:</dt>
                                                <dd className="col-sm-8">
                                                    <a className="col-sm-8" target="_blank" href={education.webbSiteUrl}>{education.educationName}</a>
                                                    <HideIfViewer>
                                                        <button onClick={(event) => { removeWebbUrlButtonClick(event) }} educationid={education.educationId} name="removeWebbUrl" type="button" className="btn btn-outline-danger btn-sm mx-3">
                                                            Ta bort
                                                        </button>
                                                    </HideIfViewer>
                                                </dd>
                                            </>
                                        }
                                        {!education.grade
                                            ?
                                            <HideIfViewer>
                                                <dt className="col-sm-4">Slutbetyg:</dt>
                                                <dd className="col-sm-8">
                                                    <button onClick={(event) => { createNewExamineGrade(event) }} educationid={education.educationId} name="addWebbUrl" type="button" className="btn btn-primary btn-sm">
                                                        Lägg till slutbetyg
                                                    </button>
                                                </dd>
                                            </HideIfViewer>
                                            :
                                            <>
                                                <dt className="col-sm-4">Slutbetyg:</dt>
                                                <dd className="col-sm-8">
                                                    <div id="grade" className={`d-inline-block ${editable.editable}`}>
                                                        <EditInfo initialText='Slutbetuget' setSaveContextKey={save.setGradeKey} thisKey='examineGrade' savePlace="grade" thisId={education.grade.gradeId} thisInfo={education.grade.examineGrade} />
                                                    </div>
                                                    <div className='d-inline-block'>
                                                        <SaveChanges btnId={education.grade.gradeId + '/examineGrade'} />
                                                    </div>
                                                    <HideIfViewer>
                                                        <div id="addCertificateBtn" className="text-white bg-success rounded mx-2 my-1" onClick={(e) => { setOpenNewGradeAppendix(true); setActiveGradeId(education.grade.gradeId) }}>
                                                            <span>Lägg till betygsbevis</span>
                                                        </div>
                                                    </HideIfViewer>
                                                    {education.grade.appendices ?
                                                        <>
                                                            {education.grade.appendices.map((appendix) =>
                                                            (
                                                                <div key={appendix.id} id="gradeBtnContainer" className='bg-primary rounded text-white mx-2 my-1'>
                                                                    <Padlock />
                                                                    <span id="certificateBtnText" key={education.grade.gradeId} onClick={(e) => { openAppendix(e) }} appendixurl={appendix.appendixUrl}>{appendix.name}</span>
                                                                    <HideIfViewer>
                                                                        <div id="removeWorkCertificate" onClick={(e) => { removeThisBtn(e) }} style={{ backgroundImage: `url(${imageUrls.removeIcon})` }} gradeid={education.grade.gradeId} appendixid={appendix.id}></div>
                                                                    </HideIfViewer>
                                                                </div>
                                                            ))
                                                            }
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    <HideIfViewer>
                                                        <button onClick={(event) => { removeGradeButtonClick(event) }} gradeid={education.grade.gradeId} educationid={education.educationId} name="removeGrade" type="button" className="btn btn-danger btn-sm mx-1">
                                                            Ta bort slutbetyg
                                                        </button>
                                                    </HideIfViewer>
                                                    <div className='col'>
                                                        <small className='d-inline-block'>
                                                            <EditInfo initialText='Information om slutbetyget' setSaveContextKey={save.setGradeKey} thisKey='discription' savePlace="grade" thisId={education.grade.gradeId} thisInfo={education.grade.discription} />
                                                        </small>
                                                        <div className='d-inline-block'>
                                                            <SaveChanges btnId={education.grade.gradeId + '/discription'} />
                                                        </div>
                                                    </div>
                                                </dd>
                                            </>
                                        }
                                    </dl>

                                    <div className={`my-3 ${editable.editable}`}>
                                        <EditText initialText='Beskrivning av din utbildning' setSaveContextKey={save.setEducationKey} savePlace="education" thisKey='description' thisId={education.educationId} thisText={education.description} />
                                        <div className='row my-2'>
                                            <SaveChanges btnId={education.educationId + '/description'} />
                                        </div>
                                    </div>

                                    <div>
                                        <a className="btn btn-primary mx-1 my-1 py-0" data-bs-toggle="collapse" href={'#' + 'collapse' + education.educationId} role="button"
                                            aria-expanded="false" aria-controls={'collapse' + education.educationId}>
                                            <BlinkingEyeBtn type='local' imageActivated={imageUrls.contractWhite} imageDeactivated={imageUrls.expandWhite} handleEvent={false} id="smallBtn" name="readMore" setStatus={false} text={`Läs mer om ${education.educationName}`} />
                                        </a>
                                        <HideIfViewer>
                                            {education.course ?
                                                <a className="btn btn-primary my-1 mx-1 py-1" role="button" thiskey='course' educationid={education.educationId} onClick={changeEducationForm}>
                                                    Ändra till utbildning
                                                </a>
                                                :
                                                <a className="btn btn-primary my-1 mx-1 py-1" role="button" thiskey='course' educationid={education.educationId} onClick={changeEducationForm}>
                                                    Ändra till kurs
                                                </a>
                                            }
                                        </HideIfViewer>
                                        <HideIfViewer>
                                            <button onClick={(event) => { removeEducationButtonClick(event) }} educationid={education.educationId} name="removeWorkEducation" type="button" className="btn btn-danger my-1 mx-1 py-1">
                                                Ta bort
                                            </button>
                                        </HideIfViewer>
                                    </div>
                                    <div className="collapse" id={'collapse' + education.educationId}>
                                        <div className={`card card-body d-inline-block my-2${editable.editable}`}>
                                            <EditText initialText='En längre beskrivning av vad du fick lära dig på utbildningen' setSaveContextKey={save.setEducationKey} thisKey='longDescription' savePlace="education" thisId={education.educationId} thisText={education.longDescription} />
                                        </div>
                                        <div className='row my-2'>
                                            <SaveChanges btnId={education.educationId + '/longDescription'} />
                                        </div>
                                    </div>
                                    <p className="border-top border-dark my-5"></p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Educations;