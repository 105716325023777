import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import urls from '../Model/fetch-url';
import useUser from './ContextAndHooks/user-context';
import HeaderImages from './header-images';
import DeleteData from '../Model/delete-data';
import EditInfo from './edit-info';
import EditText from './edit-text';
import HideIfViewer from './hide-if-viewer';
import './work-experience.css';
import { imageUrls } from '../Helpers/images';
import Modal from './modal';
import NewWorkCertificateForm from './new-work-certificate-form';
import NewExperienceWebbSiteForm from './new-experience-webb-site-form';
import GetUserData from '../Model/get-user-data';
import PatchData from '../Model/patch-data';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import PostData from '../Model/post-data';
import useSave from './ContextAndHooks/save-context';
import SaveChanges from './save-changes';
import useEditable from './ContextAndHooks/editable-context';
import { onlyDate } from '../Helpers/only-date';
import BlinkingEyeBtn from '../BlinkingEye/blinking-eye-btn';
import Padlock from './padlock';
import ChangeDate from './change-date';

const WorkExperience = (props) => {

    const user = useUser();
    const save = useSave();
    const editable = useEditable();
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const [openNewWorkCertificate, setOpenNewWorkCertificate] = useState(false);
    const [openNewWebbSite, setOpenNewWebbSite] = useState(false);
    const [activeWorkExperienceId, setActiveWorkExperienceId] = useState(null);

    const handleAddExperience = () => {
        let [today] = new Date().toISOString().split('T');

        var newWorkExperience = {
            company: "Företaget du jobbat på",
            companyWebbSiteUrl: null,
            formOfEmployment: "Sorts anställning",
            title: "Titel",
            start: today,
            end: today,
            companyDescription: "Beskrivning av företaget.",
            workDescription: "Beskrivning av arbetet och arbetsuppgifterna på företaget."
        };

        (async () => {
            const [data, err] = await PostData(urls.workExperience, user.token, newWorkExperience)
            setLoading(false);
            setErr(err);
            user.updateUser();
            if (data) {
                NotificationManager.success('Abetslivserfarenhet tillagd!');
            }
            if (err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }
        })();
    }

    const removeWorkExperienceButtonClick = (e) => {
        (async () => {
            const [data, err] = await DeleteData(urls.workExperience, user.token, e.target.getAttribute('workexperienceid'),)
            setLoading(false);
            setErr(err);
            user.updateUser();
            if (data) {
                NotificationManager.info('Abetslivserfarenhet borttagen');
            }
            if (err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }
        })();
    }

    const sortWorkExperienceList = () => {
        let sortedList = user.user.cv.workExperiences.sort((a, b) => {
            let da = new Date(a.start),
                db = new Date(b.start);
            return db - da;
        });
        return sortedList;
    };

    const openAppendix = (e) => {
        if(user.allowedReferenceAndGrade || !user.isViewer) {
            window.open(urls.rootApi + "/" + e.target.getAttribute('appendixurl'), '_blank', 'fullscreen=yes')
        }
        else {
            NotificationManager.error('Vill du se bilagan kontakta personen som skrivit cv:t för upplåsning.', 'Bilagan är låst!', 5000);
        }
    }

    const removeThisBtn = (e) => {
        (async () => {
            const [data, err] = await DeleteData(urls.workCertificate, user.token, e.target.getAttribute('certificateid'))
            setLoading(false);
            setErr(err);
            user.updateUser();
            if (data) {
                NotificationManager.info('Bilaga borttagen');
            }
            if (err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }
        })();
    }

    const setWorkCertificateId = (workExperienceId) => {
        setActiveWorkExperienceId(workExperienceId);
    }

    const removeWebbUrlButtonClick = (e) => {
        (async () => {
            const [data, err] = await GetUserData(urls.workExperience + '/' + e.target.getAttribute('workexperienceid'), user.token)
            setLoading(false);
            setErr(err);
            var workExperienceToEdit = data;
            delete workExperienceToEdit['cvId'];
            delete workExperienceToEdit['workCertificates'];
            delete workExperienceToEdit['workExperienceId'];
            workExperienceToEdit.companyWebbSiteUrl = null;

            (async () => {
                const [data, err] = await PatchData(urls.workExperience + '/' + e.target.getAttribute('workexperienceid'), user.token, workExperienceToEdit)
                setLoading(false);
                setErr(err);
                user.updateUser();
                if (data) {
                    NotificationManager.info('Webbadressen borttagen');
                }
                if (err) {
                    NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
                }
            })();
        })();
    }

    return (
        <>
            <Modal onClick={() => { setOpenNewWorkCertificate(false); setActiveWorkExperienceId(null) }} openModal={openNewWorkCertificate}>
                <div className="text-white">
                    <NewWorkCertificateForm activeWorkExperienceId={activeWorkExperienceId} closeModal={() => { setOpenNewWorkCertificate(false); setActiveWorkExperienceId(null) }} />
                </div>
            </Modal>
            <Modal onClick={() => { setOpenNewWebbSite(false); setActiveWorkExperienceId(null) }} openModal={openNewWebbSite}>
                <div className="text-white">
                    <NewExperienceWebbSiteForm activeWorkExperienceId={activeWorkExperienceId} closeModal={() => { setOpenNewWebbSite(false); setActiveWorkExperienceId(null) }} />
                </div>
            </Modal>
            <div className="col">
                <div className="row rightSide">
                    <HeaderImages />
                    <div className="row gx-0">
                        <div id="infoText" className="col-sm text">
                            <HideIfViewer>
                                <div id="editSpace" className="col rounded">
                                    <button type="button" onClick={handleAddExperience} className="btn btn-primary btn-sm">
                                        Lägg till erfarenhet
                                    </button>
                                </div>
                            </HideIfViewer>
                            <h1>Arbetslivserfarenhet</h1>
                            <p className="border-top border-dark my-3 row"></p>
                            {sortWorkExperienceList().map((experience) =>
                            (
                                <div key={experience.workExperienceId}>
                                    <div className="row">
                                        <dt className="col-sm-4">Tidsperiod:</dt>
                                        <dd className="col-sm-8 fw-light">
                                            <ChangeDate fetchObjectById={true} apiUrl={urls.workExperience} objectId={ experience.workExperienceId } startDate={experience.start} objectKey={'start'} />
                                            <span id="space"></span>
                                            <span className='fst-italic'>till</span>
                                            <span id="space"></span>
                                            <ChangeDate fetchObjectById={true} apiUrl={urls.workExperience} objectId={ experience.workExperienceId } startDate={experience.end} objectKey={'end'} />
                                        </dd>
                                        <dt className="col-sm-4">Arbetsplats:</dt>
                                        <dd className="col-sm-8 fw-light fst-italic">
                                            <div className={`d-inline-block ${editable.editable}`}>
                                                <EditInfo initialText='Företag' setSaveContextKey={save.setWorkExperienceKey} thisKey='company' savePlace="workExperience" thisId={experience.workExperienceId} thisInfo={experience.company} />
                                            </div>
                                            <SaveChanges btnId={experience.workExperienceId + '/company'} />
                                        </dd>
                                        <dt className="col-sm-4">Roll:</dt>
                                        <dd className="col-sm-8 fw-light fst-italic">
                                            <div className={`d-inline-block ${editable.editable}`}>
                                                <EditInfo initialText='Din dåvarande arbetstitel' setSaveContextKey={save.setWorkExperienceKey} thisKey='title' savePlace="workExperience" thisId={experience.workExperienceId} thisInfo={experience.title} />
                                            </div>
                                            <SaveChanges btnId={experience.workExperienceId + '/title'} />
                                        </dd>
                                        {!experience.companyWebbSiteUrl
                                            ?
                                            <HideIfViewer>
                                                <dt className="col-sm-4">Webbsida:</dt>
                                                <dd className="col-sm-8">
                                                    <button onClick={(event) => { setOpenNewWebbSite(true); setWorkCertificateId(experience.workExperienceId) }} name="addWebbUrl" type="button" className="btn btn-primary btn-sm">
                                                        Lägg till webbsida
                                                    </button>
                                                </dd>
                                            </HideIfViewer>
                                            :
                                            <>
                                                <dt className="col-sm-4">Webbsida:</dt>
                                                <dd className="col-sm-8">
                                                    <a className="col-sm-8" target="_blank" href={experience.companyWebbSiteUrl}>Gå till {experience.company} webbsida</a>
                                                    <HideIfViewer>
                                                        <button onClick={(event) => { removeWebbUrlButtonClick(event) }} workexperienceid={experience.workExperienceId} name="removeWebbUrl" type="button" className="btn btn-outline-danger btn-sm mx-3">
                                                            Ta bort
                                                        </button>
                                                    </HideIfViewer>
                                                </dd>
                                            </>
                                        }
                                    </div>

                                    <div className={editable.editable}>
                                        <EditText initialText='Beskrivning av företaget du jobbat på' setSaveContextKey={save.setWorkExperienceKey} thisKey='companyDescription' savePlace="workExperience" thisId={experience.workExperienceId} thisText={experience.companyDescription} />
                                        <div id="companyD" className='row my-2'>
                                            <SaveChanges btnId={experience.workExperienceId + '/companyDescription'} />
                                        </div>
                                    </div>

                                    <div>
                                        <a className="btn btn-primary py-0" data-bs-toggle="collapse" href={'#' + 'collapse' + experience.workExperienceId} role="button"
                                            aria-expanded="false" aria-controls={'collapse' + experience.workExperienceId}>
                                            <BlinkingEyeBtn type='local' imageActivated={imageUrls.contractWhite} imageDeactivated={imageUrls.expandWhite} handleEvent={false} id="smallBtn" name="readMore" setStatus={false} text={`Läs mer om arbetet på ${experience.company}`} />
                                        </a>
                                        <HideIfViewer>
                                            <button onClick={(event) => { removeWorkExperienceButtonClick(event) }} workexperienceid={experience.workExperienceId} name="removeWorkExperience" type="button" className="btn btn-danger mx-3 py-1">
                                                Ta bort
                                            </button>
                                        </HideIfViewer>
                                    </div>
                                    <div className="collapse" id={'collapse' + experience.workExperienceId}>
                                        <div className={`card card-body d-inline-block my-3${editable.editable}`}>
                                            <EditText initialText='Beskrivning av arbetet du utförde på företaget' setSaveContextKey={save.setWorkExperienceKey} thisKey='workDescription' savePlace="workExperience" thisId={experience.workExperienceId} thisText={experience.workDescription} />
                                            <div className='row'>
                                                <SaveChanges btnId={experience.workExperienceId + '/workDescription'} />
                                            </div>
                                        </div>
                                    </div>

                                    {experience.workCertificates.length > 0 || !editable.preview && !user.isViewer ?
                                        <div id="editSpacecertificateButtons" className="col rounded my-4">
                                            <h5 className='d-inline-block mx-2'>Bilagor:</h5>
                                            <HideIfViewer>
                                                <div id="addCertificateBtn" className="bg-warning rounded" onClick={(e) => { setOpenNewWorkCertificate(true); setWorkCertificateId(experience.workExperienceId) }}>
                                                    <span>Lägg till bilaga</span>
                                                </div>
                                            </HideIfViewer>
                                            {experience.workCertificates.map((certificate, index) =>
                                            (
                                                <div key={certificate.workCertificateId} id="certificateBtnContainer" className='bg-primary rounded text-white'>
                                                    <Padlock />
                                                    <span id="certificateBtnText" key={certificate.workCertificateId} onClick={(e) => { openAppendix(e) }} appendixurl={certificate.appendices[0].appendixUrl}>{certificate.description}</span>
                                                    <HideIfViewer>
                                                        <div id="removeWorkCertificate" onClick={(e) => { removeThisBtn(e) }} style={{ backgroundImage: `url(${imageUrls.removeIcon})` }} certificateid={certificate.workCertificateId}></div>
                                                    </HideIfViewer>
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                    <p className="border-top border-dark my-3"></p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default WorkExperience;