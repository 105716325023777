import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import { useForm } from "react-hook-form";
import urls from '../Model/fetch-url';
import GetUserData from '../Model/get-user-data';
import PatchData from '../Model/patch-data';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const NewEducationWebbSiteForm = (props) => {
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const user = useUser();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const onSubmit = (submitData) => {
        (async () => {
            const [data, err] = await GetUserData(urls.educations+'/'+props.activeEducationId, user.token)
            setLoading(false);
            setErr(err);
            var educationToEdit = data;
            delete educationToEdit['cvId'];
            delete educationToEdit['educationId'];
            delete educationToEdit['grade'];
            educationToEdit.webbSiteUrl = submitData.webbSiteUrl;

            (async () => {
                const [data, err] = await PatchData(urls.educations+'/'+props.activeEducationId, user.token, educationToEdit)
                setLoading(false);
                setErr(err);
                user.updateUser();
                if(data) {
                    NotificationManager.success('Webbsida sparad!');
                }
                if(err) {
                    NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
                }
            })();
        })();
        props.closeModal();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label>Url-adress<br />
                <input
                    defaultValue="https://"
                    {...register("webbSiteUrl", {
                        required: "Fältet är obligatoriskt"
                    })}
                    type="text"
                />
            </label>
            <div>
                <small className='error text-danger'>{errors.webbSiteUrl?.message}</small>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mx-2 my-3">Spara</button>
        </form>
    )
}
export default NewEducationWebbSiteForm;