import * as defaultProfileImage from '../Images/defaultProfileImage.svg'
import * as effektiv from '../Images/effektiv-188x150px.png'
import * as gransoverskridande from '../Images/gransoverskridande-188x150px.png'
import * as halvbildNiklas from '../Images/halvbild-niklas-250x250px.png'
import * as hjalpsam from '../Images/hjalpsam-188x150px.png'
import * as lagspelare from '../Images/lagspelare-188x150px.png'
import * as stark from '../Images/stark-188x150px.png'
import * as mycvLogo from '../Images/mycv-logo.svg'
import * as expandWhite from '../Images/expand-white.svg'
import * as contractWhite from '../Images/contract-white.svg'
import * as removeIcon from '../Images/remove-icon.svg'
import * as stayTuned from '../Images/stay-tuned.png'
import * as myCvIco from '../Images/mycv.svg'
import * as birch from '../Images/birch.svg'
import * as gettingStartedLogin from '../Images/login.png'
import * as gettingStartedCvStart from '../Images/cv-start.png'
import * as gettingStartedEditButtons from '../Images/editButtons.png'
import * as gettingStartedEditing from '../Images/editing.png'
import * as gettingStartedProfileImage from '../Images/profileImage.png'
import * as gettingStartedEducation from '../Images/education.png'
import * as gettingStartedWorkExperience from '../Images/workExperience.png'
import * as padlockUnlockedGreen from '../Images/padlock-unlocked-green.svg'
import * as padlockLockedRed from '../Images/padlock-locked-red.svg'

export const imageUrls = {
    defaultProfileImage,
    effektiv,
    gransoverskridande,
    halvbildNiklas,
    hjalpsam,
    lagspelare,
    stark,
    mycvLogo,
    expandWhite,
    contractWhite,
    removeIcon,
    stayTuned,
    myCvIco,
    birch,
    gettingStartedLogin,
    gettingStartedCvStart,
    gettingStartedEditButtons,
    gettingStartedEditing,
    gettingStartedProfileImage,
    gettingStartedEducation,
    gettingStartedWorkExperience,
    padlockUnlockedGreen,
    padlockLockedRed
}