import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import { useForm } from "react-hook-form";
import urls from '../Model/fetch-url';
import PostAppendix from '../Model/post-appendix';
import PostData from '../Model/post-data';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const NewGradeAppendixForm = (props) => {
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const user = useUser();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const onSubmit = async (submitData) => {

        var formdata = new FormData();
        formdata.append("name", submitData.name);
        formdata.append("File", submitData.image[0], submitData.image[0].name);

        const [data, err] = await PostAppendix(urls.grade + '/' + 'appendix' + '/' + props.activeGradeId, user.token, formdata)
        setLoading(false);
        setErr(err);
        user.updateUser();
        if (data) {
            NotificationManager.success('Bilaga tillagd');
        }
        if (err) {
            NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
        }
        props.closeModal();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label>Namn på bilagan<br />
                <input
                    {...register("name", {
                        required: "Fältet är obligatoriskt"
                    })}
                    type="text"
                />
            </label>
            <div>
                <small className='error text-danger'>{errors.description?.message}</small>
            </div>
            <label>Välj bilaga<br />
                <input type='file' name='picture'{...register("image", {
                    required: "Du måste välja en bilaga"
                })} />
            </label>
            <div>
                <small className='error text-danger'>{errors.image?.message}</small>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mx-2 my-3">Skicka</button>
        </form>
    )
}
export default NewGradeAppendixForm;