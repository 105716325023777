import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { imageUrls } from '../Helpers/images';
import "./working-on-it.css"

const WorkingOnIt = (props) => {

    return (
        <div className='col'>
            <div id="stayTuned" style={{ backgroundImage: `url(${imageUrls.stayTuned})` }}></div>
            <div id="stayTunedText" className='text-center'>Working on it, and the page will be ready soon!</div>
        </div>
    )
}
export default WorkingOnIt;