const GetUserData = async (url, token) => {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    try {
        const response = await fetch(`${url}`, requestOptions);
            let data = await response.json();
            return  [data, null]
        } catch(err) {
            return  [null, err]
        }
}
export default GetUserData;
