import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import { useForm } from "react-hook-form";
import urls from '../Model/fetch-url';
import "./new-cv-form.css";
import PostRegisterViewer from '../Model/post-register-viewer';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const NewViewerForm = (props) => {
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const user = useUser();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (submitData) => {
        (async () => {
            const [data, err] = await PostRegisterViewer(urls.registerViewer, user.token, submitData)
            setLoading(false);
            setErr(err);
            if(data) {
                NotificationManager.success('Ny besökare skapad');
            }
            if(err) {
                NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
            }
        })();
        props.closeModal();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label>Förnamn<br />
                <input
                    {...register("firstName", {
                        required: "Fältet är obligatoriskt"
                    })}
                    type="text"
                />
            </label>
            <div>
                <small className='error text-danger'>{errors.lastName?.message}</small>
            </div>
            <label>Efternamn<br />
                <input
                    {...register("lastName", {
                        required: "Fältet är obligatoriskt"
                    })}
                    type="text"
                />
            </label>
            <div>
                <small className='error text-danger'>{errors.lastName?.message}</small>
            </div>
            <label>E-post<br />
                <input
                    {...register("email", {
                        required: "Fältet är obligatoriskt"
                    })}
                    type="email"
                />
            </label>
            <div>
                <small className='error text-danger'>{errors.email?.message}</small>
            </div>
            <label>Lösenord<br />
                <input
                    {...register("password", {
                        required: "Fältet är obligatoriskt"
                    })}
                    type="password"
                />
            </label>
            <div>
                <small className='error text-danger'>{errors.password?.message}</small>
            </div>

            <label>Företag<br />
                <input
                    {...register("viewCompany", {
                        required: "Fältet är obligatoriskt"
                    })}
                    type="text"
                />
            </label>
            <div>
                <small className='error text-danger'>{errors.viewCompany?.message}</small>
            </div>
            <label>Tillåt att se referenser:<br />
                <input
                    {...register("alowedReferenceAndGrade")}
                    type="checkbox"
                />
            </label>
            
            <div id="btnContainer">
                <button type="submit" className="btn btn-primary btn-sm mx-2">Skapa konto</button>
            </div>
        </form>
    )
}
export default NewViewerForm;