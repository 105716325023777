import React, { useState, useEffect, useContext, createContext, useRef, useMemo } from 'react';

const EditContext = createContext();

export const EditableProvider = (props) => {

    const [editable, setEditable] = useState('');
    const [buttonText, setButtonText] = useState('Markera redigeringsbar text');
    const [preview, setPreview] = useState(false);
    const [previewButtonText, setPreviewButtonText] = useState('Förhandsgranska');

    return (
        <EditContext.Provider value={{
            editable: editable,
            setEditable: setEditable,
            buttonText: buttonText,
            setButtonText: setButtonText,
            preview: preview,
            setPreview: setPreview,
            previewButtonText: previewButtonText,
            setPreviewButtonText: setPreviewButtonText
        }}>
            {props.children}
        </EditContext.Provider>
    );
}

export default () => useContext(EditContext);