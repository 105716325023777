import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import { useForm } from "react-hook-form";
import urls from '../Model/fetch-url';
import PostImage from '../Model/post-image';
import PostData from '../Model/post-data';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const NewWorkCertificateForm = (props) => {
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const user = useUser();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        var newWorkCertifivcate = { description: data.description };
        var file = data.image[0];
        var imageName = data.image[0].name;

        (async () => {
            const [data, err] = await PostData(urls.workCertificate+'/'+props.activeWorkExperienceId, user.token, newWorkCertifivcate)
            setLoading(false);
            setErr(err);
            var thisWorkCertificateId = data.workCertificateId;

            (async () => {
                const [data, err] = await PostImage(urls.workExperience+'/'+'appendix'+'/'+thisWorkCertificateId, user.token, file, imageName, 'File')
                setLoading(false);
                setErr(err);
                user.updateUser();
                if(data) {
                    NotificationManager.success('Bilaga tillagd');
                }
                if(err) {
                    NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
                }
            })();
        })();
        props.closeModal();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label>Beskrivning av bilaga<br />
                <input
                    {...register("description", {
                        required: "Fältet är obligatoriskt"
                    })}
                    type="text"
                />
            </label>
            <div>
                <small className='error text-danger'>{errors.description?.message}</small>
            </div>
            <label>Välj bilaga<br />
                <input type='file' name='picture'{...register("image", {
                    required: "Du måste välja en bilaga"
                })} />
            </label>
            <div>
                <small className='error text-danger'>{errors.image?.message}</small>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mx-2 my-3">Skicka</button>
        </form>
    )
}
export default NewWorkCertificateForm;