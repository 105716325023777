////@ts-check
import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { useStorageState } from 'react-storage-hooks';
import useUser from './ContextAndHooks/user-context';
import useSave from './ContextAndHooks/save-context';
import useEditable from './ContextAndHooks/editable-context';

    const EditInfo = (props) => {
    const user = useUser();
    const save = useSave();
    const editable = useEditable();
    const [initialValue, setInitialValue] = useStorageState(localStorage, props.thisId+'/'+props.thisKey, [
        {
            type: 'paragraph',
            children: [{ text: !props.thisInfo? props.initialText : props.thisInfo
            }]
        }
    ]);

    // useEffect(() => {
    //     let newValue = { ...initialValue };
    //     console.log('newValue', newValue);
    //     newValue[0].children[0].text = props.thisInfo? props.thisInfo : props.initialText;
    //     setInitialValue([newValue[0]]);
    // }, [user])

    const [editor] = useState(() => withReact(createEditor()))

    const renderElement = useCallback(props => {
        return <DefaultElement {...props} />
    }, [])

    const checkThisReadOnly = () => {
        if (save.inEditMode && save.inEditMode !== props.thisId+'/'+props.thisKey) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <Slate
                editor={editor}
                value={initialValue}
                onChange={value => {
                    const isAstChange = editor.operations.some(
                        op => 'set_selection' !== op.type
                    )
                    if (!isAstChange) {
                        if(save.inEditMode && save.inEditMode !== props.thisId+'/'+props.thisKey) {
                            save.setErr(true);
                        }
                    }
                    if (isAstChange) {
                        save.setInEditMode(props.thisId+'/'+props.thisKey);
                        setInitialValue(value);
                        save.setSave(true);
                        save.setButton(props.thisId+'/'+props.thisKey);
                        save.setSavePlace(props.savePlace);
                        props.setSaveContextKey(props.thisId+'/'+props.thisKey);
                    }
                }}
            >
            <Editable
            editor={editor}
            readOnly={user.isViewer || checkThisReadOnly() || editable.preview}
            renderElement={renderElement}
            />
            </Slate>
        </>
    )
}
const DefaultElement = props => {
    return <div>
        {
        props.children
        }
    </div>
}
export default EditInfo;