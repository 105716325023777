const PatchData = async (url, token, object) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(object);

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${url}`, requestOptions);
        let data = await response.json();
        return [data, null]
    } catch (err) {
        return [null, err]
    }
}
export default PatchData;
