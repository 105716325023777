import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { Outlet, Link } from "react-router-dom";
import { imageUrls } from '../Helpers/images';
import urls from '../Model/fetch-url';
import useUser from './ContextAndHooks/user-context';
import useSave from './ContextAndHooks/save-context';
import './menu.css';
import ProfileImageForm from './profile-image-form';
import Modal from './modal';
import NewViewerForm from './new-viewer-form';
import EditViewerForm from './edit-viewer-form';
import ChoseViewer from './chose-viewer';
import BlinkingEyeBtn from '../BlinkingEye/blinking-eye-btn';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import HideIfViewer from './hide-if-viewer';
import 'react-notifications/lib/notifications.css';
import SaveChanges from './save-changes';
import useEditable from './ContextAndHooks/editable-context';
import EditInfo from './edit-info';
import ChoseUser from './chose-user';
import HandleUserForm from './handle-user-form';

const Menu = (props) => {
    const user = useUser();
    const save = useSave();
    const editable = useEditable();
    const [openImageModal, setOpenImageModal] = useState(false);
    const [openNewViewerModal, setOpenNewViewerModal] = useState(false);
    const [openEditViewerModal, setOpenEditViewerModal] = useState(false);
    const [openChoseViewerModal, setOpenChoseViewerModal] = useState(false);
    const [openHandleUserModal, setOpenHandleUserModal] = useState(false);
    const [openChoseUserModal, setOpenChoseUserModal] = useState(false);
    const [chosenViewerId, setChosenViewerId] = useState(null);
    const [chosenUserId, setChosenUserId] = useState(null);


    const logout = () => {
        window.localStorage.clear();
        user.setUser(null);
        user.setIsLoggedIn(false);
        user.setIsViewer(false);
        window.location.reload();
    }

    const openViewerEditor = (id) => {
        setChosenViewerId(id);
        setOpenEditViewerModal(true);
    }

    const openUserEditor = (id) => {
        setChosenUserId(id);
        setOpenHandleUserModal(true);
    }

    const toggleEditable = () => {
        if (editable.editable === 'editable') {
            editable.setEditable('');
            editable.setButtonText('Framhäv redigeringsbar text');
        } else {
            editable.setEditable('editable');
            editable.setButtonText('Ta bort markering från text')
        }
    }

    const togglePreview = () => {
        if (!editable.preview) {
            editable.setPreview(true);
            editable.setPreviewButtonText('Tillbaka till redigering');
        } else {
            editable.setPreview(false);
            editable.setPreviewButtonText('Förhandsgranska');
        }
    }

    const handleUsers = () => {
        setOpenChoseUserModal(true);
    }

    if (user.user.cv) {
        return (
            <>
                <NotificationContainer />
                <Modal onClick={() => { setOpenImageModal(false) }} openModal={openImageModal}>
                    <div className="text-white">
                        <ProfileImageForm closeModal={() => { setOpenImageModal(false) }} />
                    </div>
                </Modal>
                <Modal onClick={() => { setOpenNewViewerModal(false) }} openModal={openNewViewerModal}>
                    <div className="text-white">
                        <NewViewerForm closeModal={() => { setOpenNewViewerModal(false) }} />
                    </div>
                </Modal>
                <Modal onClick={() => { setOpenChoseViewerModal(false) }} openModal={openChoseViewerModal}>
                    <div className="text-white">
                        <ChoseViewer openViewerEditor={openViewerEditor} closeModal={() => { setOpenChoseViewerModal(false) }} />
                    </div>
                </Modal>
                <Modal onClick={() => { setOpenEditViewerModal(false) }} openModal={openEditViewerModal}>
                    <div className="text-white">
                        <EditViewerForm viewerId={chosenViewerId} openViewerEditor={openViewerEditor} closeModal={() => { setOpenEditViewerModal(false) }} />
                    </div>
                </Modal>
                <Modal onClick={() => { setOpenChoseUserModal(false) }} openModal={openChoseUserModal}>
                    <div className="text-white">
                        <ChoseUser openUserEditor={openUserEditor} closeModal={() => { setOpenChoseUserModal(false) }} />
                    </div>
                </Modal>
                <Modal onClick={() => { setOpenHandleUserModal(false) }} openModal={openHandleUserModal}>
                    <div className="text-white">
                        <HandleUserForm userId={chosenUserId} closeModal={() => { setOpenHandleUserModal(false) }} />
                    </div>
                </Modal>
                <div id="sideBar" className="col-sm-4">
                    <div id="profile" className="flex-shrink-0 p-3 bg-black">
                        <div id="profileContainer">
                            <div id="profileImageContainer" style={{
                                backgroundImage: `url(${user.user.profileImageUrl
                                    ?
                                    urls.image + user.user.profileImageUrl
                                    :
                                    props.profileImage
                                    })`
                            }}>
                                <HideIfViewer>
                                    <i onClick={() => { setOpenImageModal(true) }} id="editIcon" className="bi bi-pencil-square"></i>
                                </HideIfViewer>
                            </div>
                            <div className="text-white" id="profileName">
                                <EditInfo initialText='Förnamn' setSaveContextKey={save.setUserKey} thisKey='firstName' savePlace="user" thisId={user.user.userId} thisInfo={user.user.firstName} />
                                <span id="space"></span>
                                <EditInfo initialText='Efternamn' setSaveContextKey={save.setUserKey} thisKey='lastName' savePlace="user" thisId={user.user.userId} thisInfo={user.user.lastName} />
                            </div>
                            <SaveChanges btnId={user.user.userId + '/firstName'} />
                            <SaveChanges btnId={user.user.userId + '/lastName'} />
                        </div>
                    </div>
                    <div id="sideBarMeny" className="flex-shrink-0 p-3 bg-black">
                        <ul className="list-unstyled px-3">
                            <li className="mb-1">
                                <a className="collapsed text-white no-text-decoration"
                                    data-bs-toggle="collapse" data-bs-target="#about-collapse" aria-expanded="true">
                                    <BlinkingEyeBtn type='local' imageActivated={imageUrls.contractWhite} imageDeactivated={imageUrls.expandWhite} handleEvent={false} id="smallBtn" name="about" setStatus={true} text={`Om ${user.user.firstName} ${user.user.lastName}`} />
                                </a>
                                <div className="collapse show" id="about-collapse">
                                    <ul className="list-unstyled py-2 px-3 small">
                                        <Link onClick={() => { user.updateUser() }} className="text-white no-text-decoration" id="navLink" to="/start"><BlinkingEyeBtn type='global' handleEvent={false} id="smallBtn" name="/start" text="Jag som person" /></Link>
                                        <Link onClick={() => { user.updateUser() }} className="text-white no-text-decoration" id="navLink" to="/personal-data"><BlinkingEyeBtn type='global' handleEvent={false} id="smallBtn" name="/personal-data" text="Mina personuppgifter" /></Link>
                                        <Link onClick={() => { user.updateUser() }} className="text-white no-text-decoration" id="navLink" to="/contact"><BlinkingEyeBtn type='global' handleEvent={false} id="smallBtn" name="/contact" text="Mina kontaktuppgifter" /></Link>
                                    </ul>
                                </div>
                            </li>
                            <li className="mb-1">
                                <a className="collapsed text-white no-text-decoration"
                                    data-bs-toggle="collapse" data-bs-target="#workExperience-collapse" aria-expanded="true">
                                    <BlinkingEyeBtn type='local' imageActivated={imageUrls.contractWhite} imageDeactivated={imageUrls.expandWhite} handleEvent={false} id="smallBtn" name="work-experience" text="Erfarenheter och utbildningar" />
                                </a>
                                <div className="collapse" id="workExperience-collapse">
                                    <ul className="list-unstyled py-2 px-3 small">
                                        <Link onClick={() => { user.updateUser() }} className="text-white no-text-decoration" id="navLink" to="/work-experience"><BlinkingEyeBtn type='global' handleEvent={false} id="smallBtn" name="/work-experience" text="Arbetslivserfarenhet" /></Link>
                                        <Link onClick={() => { user.updateUser() }} className="text-white no-text-decoration" id="navLink" to="/educations"><BlinkingEyeBtn type='global' handleEvent={false} id="smallBtn" name="/educations" text="Utbildningar och kurser" /></Link>
                                    </ul>
                                </div>
                            </li>
                            <li className="mb-1">
                                <a className="collapsed text-white no-text-decoration"
                                    data-bs-toggle="collapse" data-bs-target="#references-collapse" aria-expanded="true">
                                    <BlinkingEyeBtn type='local' imageActivated={imageUrls.expandWhite} imageDeactivated={imageUrls.contractWhite} handleEvent={false} id="smallBtn" name="about" setStatus={true} text="Referenser" />
                                </a>
                                <div className="collapse" id="references-collapse">
                                    <ul className="list-unstyled py-2 px-3 small">
                                        {!user.user.cv.gitHubApi && user.isViewer ?
                                            null
                                            :
                                            <Link onClick={() => { user.updateUser() }} className="text-white no-text-decoration" id="navLink" to="/github"><BlinkingEyeBtn type='global' handleEvent={false} id="smallBtn" name="/github" text="GitHub" /></Link>
                                        }
                                        <Link onClick={() => { user.updateUser() }} className="text-white no-text-decoration" id="navLink" to="/working-on-it"><BlinkingEyeBtn type='global' handleEvent={false} id="smallBtn" name="/working-on-it" text="Arbetsprover" /></Link>
                                        <Link onClick={() => { user.updateUser() }} className="text-white no-text-decoration" id="navLink" to="/working-on-it"><BlinkingEyeBtn type='global' handleEvent={false} id="smallBtn" name="/working-on-it" text="Personliga referenser" /></Link>
                                    </ul>
                                </div>
                            </li>
                            <li className="border-top my-3"></li>
                            <li className="mb-1">
                                <a className="collapsed text-white no-text-decoration"
                                    data-bs-toggle="collapse" data-bs-target="#documentAccess-collapse" aria-expanded="true">
                                    <BlinkingEyeBtn type='local' imageActivated={imageUrls.expandWhite} imageDeactivated={imageUrls.contractWhite} handleEvent={false} id="smallBtn" name="about" setStatus={true} text="Dokumentåtkomst" />
                                </a>
                                <div className="collapse" id="documentAccess-collapse">
                                    <ul className="list-unstyled py-2 px-3 small">
                                        <Link onClick={() => { user.updateUser() }} className="text-white no-text-decoration" id="navLink" to="/working-on-it"><BlinkingEyeBtn type='global' handleEvent={false} id="smallBtn" name="/working-on-it" text="Lås upp betyg" /></Link>
                                        <Link onClick={() => { user.updateUser() }} className="text-white no-text-decoration" id="navLink" to="/working-on-it"><BlinkingEyeBtn type='global' handleEvent={false} id="smallBtn" name="/working-on-it" text="Lås upp personliga referenser" /></Link>
                                    </ul>
                                </div>
                            </li>
                            <li className="border-top my-3"></li>
                            <div className='row'>
                                {!user.isViewer ?
                                    <button onClick={() => { togglePreview() }} className="btn btn-outline-light mx-2 my-2">
                                        {editable.previewButtonText}
                                    </button>
                                    :
                                    null
                                }
                                <HideIfViewer>
                                    <button onClick={() => { toggleEditable() }} className="btn btn-outline-warning mx-2 my-2">
                                        {editable.buttonText}
                                    </button>
                                </HideIfViewer>
                                <HideIfViewer>
                                    <button onClick={() => { setOpenNewViewerModal(true) }} className="btn btn-outline-primary mx-2 my-2">
                                        Lägg till besökare
                                    </button>
                                    <button onClick={() => { setOpenChoseViewerModal(true) }} className="btn btn-outline-secondary mx-2 my-2">
                                        Redigera besökare
                                    </button>
                                </HideIfViewer>
                                {user.user.isAdmin ?
                                    <button onClick={handleUsers} id="removeUsersBtn" name="removeUsersBtn" type="button" className="btn btn-outline-success mx-2 my-2 btn-block">
                                        Hantera användare
                                    </button>
                                    :
                                    null
                                }
                                <button onClick={ logout } id="logoutBtn" name="logoutBtn" type="button" className="btn btn-outline-danger mx-2 my-2 btn-block">
                                    Logga ut
                                </button>

                            </div>
                        </ul>
                    </div>
                </div>
                <Outlet />
            </>
        )
    }
}
Menu.defaultProps = {
    profileImage: imageUrls.defaultProfileImage,
}
export default Menu;