import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import useSave from './ContextAndHooks/save-context';
import HeaderImages from './header-images';
import Languages from './languages';
import './personal-data.css';
import SaveChanges from './save-changes';
import useEditable from './ContextAndHooks/editable-context';
import CivilStatus from './civil-status';
import DrivingLicenseCode from './driving-license-code';
import EditInfo from './edit-info';
import urls from '../Model/fetch-url';
import ChangeDate from './change-date';

const PersonalData = (props) => {
    const user = useUser();
    const save = useSave();
    const editable = useEditable();

    return (
        <>
            <div className="col">
                <div className="row rightSide">
                    <HeaderImages />
                    <div className="row gx-0">
                        <div id="infoText" className="col-sm text">
                            <h1>Personuppgifter</h1>
                            <p className="border-top border-dark my-3"></p>
                            <div id="personalDataRow" className="row">
                                <CivilStatus />
                                <dt className="col-sm-4">Nationalitet:</dt>
                                <dd className="col-sm-8">
                                    <div className={`d-inline-block ${editable.editable}`}>
                                        <EditInfo initialText='Nationalitet' setSaveContextKey={save.setUserKey} thisKey='nationality' savePlace="user" thisId={user.user.userId} thisInfo={user.user.nationality} />
                                    </div>
                                    <SaveChanges btnId={user.user.userId + '/nationality'} />
                                </dd>
                                <dt className="col-sm-4">Födelsedag:</dt>
                                <dd className="col-sm-8">
                                    <ChangeDate fetchObjectById={false} objectId={user.userId} apiUrl={urls.users} objectToEdit={user.user} startDate={user.user.birthday} objectKey={'birthday'} />
                                </dd>
                                <DrivingLicenseCode />
                                <Languages />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PersonalData;