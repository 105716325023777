import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import "./modal.css";

const Modal = (props) => {
    const user = useUser();

    return (
        <>
            {props.openModal
                ?
                <div id="modalContainer">
                    <div id="modal" className={`${props.background} overflow-auto`} >
                        <div id="closeBtn" className={props.colorX} onClick={() => props.onClick()}> X </div>
                        {props.children}
                    </div>
                </div>
                :
                null
            }
        </>
    )
}
Modal.defaultProps = {
    background: 'bg-black',
    colorX: 'text-white'
}
export default Modal;