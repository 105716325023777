import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import { useForm } from "react-hook-form";
import urls from '../Model/fetch-url';
import PostImage from '../Model/post-image';
import DeleteImage from '../Model/delete-image';
import "./profile-image-form.css";

const ProfileImageForm = (props) => {
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const user = useUser();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        var file = data.image[0];
        var imageName = data.image[0].name;

        (async () => {
            const [data, err] = await PostImage(urls.profileImage, user.token, file, imageName, 'ProfileImage')
            setLoading(false);
            setErr(err);
            user.updateUser();
        })();
        props.closeModal();
    };

    const removeImageClick = (e) => {
        (async () => {
            const [data, err] = await DeleteImage(urls.profileImage, user.token);
            setLoading(false);
            setErr(err);
            user.updateUser();
        })();
        props.closeModal();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <input type='file' name='picture'{...register("image", { required: true })} />
            {errors.exampleRequired && <span>This field is required</span>}
            <button type="submit" className="btn btn-primary btn-sm mx-2">Skicka</button>
            <button onClick={(e) => { removeImageClick(e) }} id="deleteImageBtn" name="deleteImageBtn" type="button" className="btn btn-outline-danger btn-sm mx-3">Ta bort bild</button>
        </form>
    )
}
export default ProfileImageForm;