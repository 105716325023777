import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import HeaderImages from './header-images';
import './git-hub.css';
import GetGithubData from '../Model/get-github-data';
import HideIfViewer from './hide-if-viewer';
import GitHubForm from './github-form';
import Modal from './modal';
import GetUserData from '../Model/get-user-data';
import urls from '../Model/fetch-url';
import NotificationManager from 'react-notifications/lib/NotificationManager';

const GitHub = (props) => {
    
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const user = useUser();
    const [repos, setRepos] = useState();
    const [openGitHubModal, setOpenGitHubModal] = useState(false);
    const [gitHubApi, setGitHubApi] = useState(user.user.cv.gitHubApi);
    const [gitHubApiUrl, setGitHubApiUrl] = useState(user.user.cv.gitHubApiUrl);

    useEffect(() => {
        setRepos();
        (async () => {
            if(gitHubApi && gitHubApiUrl) {
                (async () => {
                    const [data, err] = await GetGithubData(gitHubApiUrl)
                    setLoading(false);
                    setErr(err);
                    if (data) {
                        setRepos(data)
                    }
                    if (err) {
                        NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
                    }
                })();
            }
        })();
    },[gitHubApiUrl, gitHubApi])

    return (
        <>
            <Modal onClick={() => { setOpenGitHubModal(false) }} openModal={openGitHubModal}>
                <div className="text-white">
                    <GitHubForm gitHubApi={ gitHubApi } gitHubApiUrl={ gitHubApiUrl } setGitHubApi={ setGitHubApi } setGitHubApiUrl={ setGitHubApiUrl }closeModal={() => { setOpenGitHubModal(false) }} />
                </div>
            </Modal>
            <div className="col">
                <div className="row rightSide">
                    <HeaderImages />
                    <div id="repos" className="col-sm gx-0">
                        <HideIfViewer>
                            <div id="editSpace" className="col rounded mx-2 my-2">
                                <button type="button" onClick={() => { setOpenGitHubModal(true) }} className="btn btn-primary btn-sm">
                                    {!gitHubApi ?
                                        'Klicka här för att visa dina publika och stjärnmärkta repos'
                                        :
                                        'Ändra eller dölja GitHub repos'
                                    }
                                </button>
                            </div>
                        </HideIfViewer>
                        {repos && gitHubApi ?
                            repos.map((repo) =>
                                <div key={repo.id} className="col text repo">
                                    <h2>{repo.name}</h2>
                                    <p className="lead">{repo.description}</p>
                                    <div className='d-inline-block'>
                                        <a className="btn btn-primary mx-2 my-2" href={repo.html_url} target="_blank" role="button">Till repot på GitHub</a>
                                    </div>
                                    {repo.has_pages ?
                                        <div className='d-inline-block'>
                                            <a className="btn btn-info mx-2 my-2" href={`https://nikbjo72.github.io/${repo.name}`} target="_blank" role="button">Titta på resultatet via GitHub Pages</a>
                                        </div>
                                        :
                                        null
                                    }
                                    <p className="border-top border-dark my-3 mx-2"></p>
                                </div>
                            )
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default GitHub;