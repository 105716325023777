import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import useEditable from './ContextAndHooks/editable-context';
import { onlyDate } from '../Helpers/only-date';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PatchData from '../Model/patch-data';
import { NotificationManager } from 'react-notifications';
import SaveBtn from './save-btn';
import propTypes from 'prop-types';
import './change-date.css';
import GetUserData from '../Model/get-user-data';
import "react-datepicker/dist/react-datepicker.css";

const ChangeDate = (props) => {
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const user = useUser();
    const editable = useEditable();
    const [startDate, setStartDate] = useState(new Date(props.startDate));
    const [visibility, setVisibility] = useState('hidden');
    const [editedObject, setEditedObject] = useState();

    useEffect(() => {
        if(props.fetchObjectById && onlyDate(props.startDate) != startDate.getFullYear() + '-' + (("0"+(startDate.getMonth()+1)).slice(-2)) + '-' + ("0"+startDate.getDate()).slice(-2)) {
            (async () => {
                const [data, err] = await GetUserData(props.apiUrl +'/'+ props.objectId, user.token)
                setLoading(false);
                setErr(err);
                if (data) {
                    setEditedObject(data);
                }
            })(); 
        } else {
            setEditedObject({...props.objectToEdit});
        }
    },[startDate])

    useEffect(() => {
        if (onlyDate(props.startDate) != startDate.getFullYear() + '-' + (("0"+(startDate.getMonth()+1)).slice(-2)) + '-' + ("0"+startDate.getDate()).slice(-2)) {
            setVisibility('show');
        } else {
            setVisibility('hidden');
        }
    }, [startDate])

    const saveBirthdayHandler = async () => {
        setVisibility('hidden');
        editedObject[props.objectKey] = startDate.getFullYear() + '-' + (("0"+(startDate.getMonth()+1)).slice(-2)) + '-' + ("0"+startDate.getDate()).slice(-2);

        const [data, err] = await PatchData(props.apiUrl + '/' + props.objectId, user.token, editedObject)
        setLoading(false);
        setErr(err);
        user.updateUser();
        if (data) {
            NotificationManager.success('Födelsedag uppdaterad!');
        }
        if (err) {
            NotificationManager.error('Prova att uppdatera sidan och försök igen.', 'Något gick fel!', 5000);
        }
    }

    return (
        <>
            <div className={ `d-inline-block ${editable.editable}` }>
                <DatePicker
                    selected={ startDate }
                    onChange={ (date) => setStartDate(date) }
                    dateFormat="yyyy-MM-dd"
                    className='customCss'
                />
            </div>
            <SaveBtn onClick={saveBirthdayHandler} class={visibility} />
        </>
    )
}
ChangeDate.propTypes = {
    objectToEdit: propTypes.object,
    startDate: propTypes.any,
    objectKey: propTypes.string.isRequired,
    apiUrl: propTypes.any.isRequired,
    objectId: propTypes.string,
    fetchObjectById: propTypes.bool.isRequired
};
ChangeDate.defaultProps = {
    startDate: new Date(),
    objectId: null
}
export default ChangeDate;