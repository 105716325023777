import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import useSave from './ContextAndHooks/save-context';
import SkillGraph from './skill-graph';
import PostData from '../Model/post-data';
import DeleteData from '../Model/delete-data';
import urls from '../Model/fetch-url';
import HideIfViewer from './hide-if-viewer';
import "./languages.css";
import SaveChanges from './save-changes';
import useEditable from './ContextAndHooks/editable-context';
import EditInfo from './edit-info';

const Languages = (props) => {

    const user = useUser();
    const save = useSave();
    const editable = useEditable();
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();

    const findPrimaryLanguage = () => {
        return user.user.cv.languages.find(l => l.primary === true);
    }

    const addLanguageHandler = (e) => {
        var newLanguage;
        if (e.target.id === 'secondaryLanguage') {
            newLanguage = {
                thisLanguage: "Språk",
                primary: false,
                secondary: true,
                speakingSkill: 1,
                writingSkill: 1
            }
        } else {
            newLanguage = {
                thisLanguage: "Språk",
                primary: true,
                secondary: false,
                speakingSkill: 1,
                writingSkill: 1
            }
        }
        (async () => {
            const [data, err] = await PostData(urls.language, user.token, newLanguage)
            setLoading(false);
            setErr(err);
            user.updateUser();
        })();
    };

    const onRemoveBtnClick = (e) => {

        (async () => {
            const [data, err] = await DeleteData(urls.language, user.token, e.target.id)
            setLoading(false);
            setErr(err);
            user.updateUser();
        })();
    };

    return (
        <>
            {user.user.cv.languages.length > 0
                ?
                <>
                    <h3 className="gy-4">Språk</h3>
                    <p className="border-top my-1 border-dark"></p>
                    <div className="row">
                        <dt className="col-sm-4">Modersmål:</dt>
                        <dd id='languageContainer'>
                            <div id="language" className={`d-inline-block ${editable.editable}`}>
                                <EditInfo initialText='Språk' setSaveContextKey={save.setLanguageKey} thisKey='thisLanguage' savePlace="language" thisId={findPrimaryLanguage().languageId} thisInfo={findPrimaryLanguage().thisLanguage} />
                            </div>
                            <SaveChanges btnId={`${findPrimaryLanguage().languageId}/thisLanguage`} />
                            <SkillGraph skill="speakingSkill" languageId={findPrimaryLanguage().languageId} label={'Tala:'} level={findPrimaryLanguage().speakingSkill} />
                            <SkillGraph skill="writingSkill" languageId={findPrimaryLanguage().languageId} label={'Skriva:'} level={findPrimaryLanguage().writingSkill} />
                        </dd>
                        {user.user.cv.languages.find(l => l.secondary === true)
                            ?
                            <>
                                <dt className="col-sm-4">Andraspråk:</dt>
                                {user.user.cv.languages.map((language) =>
                                (
                                    language.secondary
                                        ?
                                        <dd id='languageContainer' key={language.languageId}>
                                            <div id="language" className={`d-inline-block ${editable.editable}`}>
                                                <EditInfo initialText='Språk' setSaveContextKey={save.setLanguageKey} thisKey='thisLanguage' savePlace="language" thisId={language.languageId} thisInfo={language.thisLanguage} />
                                            </div>
                                            <SaveChanges btnId={`${language.languageId}/thisLanguage`} />
                                            <SkillGraph skill="speakingSkill" languageId={language.languageId} label={'Tala:'} level={language.speakingSkill} />
                                            <SkillGraph skill="writingSkill" languageId={language.languageId} label={'Skriva:'} level={language.writingSkill} />
                                            <HideIfViewer>
                                                <button onClick={(e) => { onRemoveBtnClick(e) }} id={language.languageId} name="removeLanguageBtn" type="button" className="btn btn-danger btn-sm mx-3 py-0 removeLanguageBtn">
                                                    Ta bort
                                                </button>
                                            </HideIfViewer>
                                        </dd>
                                        :
                                        null
                                ))
                                }
                                <div>
                                    <HideIfViewer>
                                        <button onClick={(e) => { addLanguageHandler(e) }} id="secondaryLanguage" type="button" className="btn btn-primary btn-sm">
                                            Lägg till ett andraspråk
                                        </button>
                                    </HideIfViewer>
                                </div>
                            </>
                            :
                            <div>
                                <HideIfViewer>
                                    <button onClick={(e) => { addLanguageHandler(e) }} id="secondaryLanguage" type="button" className="btn btn-primary btn-sm">
                                        Lägg till ett andraspråk
                                    </button>
                                </HideIfViewer>
                            </div>
                        }
                    </div>
                </>
                :
                <div>
                    <HideIfViewer>
                        <button onClick={(e) => { addLanguageHandler(e) }} id="primaryLanguage" type="button" className="btn btn-primary btn-sm">
                            Lägg till ett modersmål
                        </button>
                    </HideIfViewer>
                </div>
            }
        </>
    )
}
export default Languages;