import React, { useState, useEffect, useContext, createContext, useRef, useMemo } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const SaveContext = createContext();

export const SaveProvider = (props) => {

    const [save, setSave] = useState(false);
    const [err, setErr] = useState(false);
    const [savePlace, setSavePlace] = useState('');
    const [userKey, setUserKey] = useState('');
    const [cvKey, setCvKey] = useState('');
    const [languageKey, setLanguageKey] = useState('');
    const [workExperienceKey, setWorkExperienceKey] = useState('');
    const [educationKey, setEducationKey] = useState('');
    const [gradeKey, setGradeKey] = useState('');
    const [button, setButton] = useState(null);
    const [inEditMode, setInEditMode] = useState();
    const buttonRef = useRef(null);

    useEffect(() => {
        if(err) {
            NotificationManager.error('Sparaknappen finns i slutet av texten du ändrar.', 'Spara fältet innan du fortsätter ändra!', 5000);  
        }
    })

    return (
        <SaveContext.Provider value={{
            save: save,
            setSave: setSave,
            savePlace: savePlace,
            setSavePlace: setSavePlace,
            languageKey: languageKey,
            setLanguageKey: setLanguageKey,
            workExperienceKey: workExperienceKey,
            setWorkExperienceKey: setWorkExperienceKey,
            educationKey: educationKey,
            setEducationKey: setEducationKey,
            gradeKey: gradeKey,
            setGradeKey: setGradeKey,
            button: button,
            setButton: setButton,
            buttonRef: buttonRef,
            inEditMode: inEditMode,
            setInEditMode: setInEditMode,
            setErr: setErr,
            userKey: userKey,
            setUserKey: setUserKey,
            cvKey: cvKey,
            setCvKey: setCvKey,
        }}>
            {props.children}
        </SaveContext.Provider>
    );
}

export default () => useContext(SaveContext);