import React, { useState, useEffect, useContext,  createContext } from 'react';
import useUser from './ContextAndHooks/user-context';
import useEditable from './ContextAndHooks/editable-context';

const HideIfViewer = (props) => {
    const user = useUser();
    const editable = useEditable();

    return (
        <>
            {user.isViewer || editable.preview
            ?
            null
            :
            props.children
            }
        </>
    );
}

export default HideIfViewer;