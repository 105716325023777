import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useUser from './ContextAndHooks/user-context';
import { useForm } from "react-hook-form";
import urls from '../Model/fetch-url';
import "./new-cv-form.css";
import PostRegisterUser from '../Model/post-register-user';

const NewCvForm = (props) => {
    const [adminError, setAdminError] = useState(false);
    const [checkbox, setCheckbox] = useState(false);
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const user = useUser();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (submitData) => {
        submitData.isAdmin = (submitData.isAdmin === "true");
        const submit = async (onSubmitData) => {
            const [data, err] = await PostRegisterUser(urls.register, onSubmitData)
            setLoading(false);
            setErr(err);
        };

        if (submitData.isAdmin === false) {
            delete submitData['createAdminPassword'];
            setAdminError(false);
            props.setRegisterEmail(submitData.email);
            props.setRegisterFirstName(submitData.firstName);
            submit(submitData);
        }
        else if (submitData.isAdmin && submitData.createAdminPassword === "jagVillBliAdmin!") {
            delete submitData['createAdminPassword'];
            setAdminError(false);
            props.setRegisterEmail(submitData.email);
            props.setRegisterFirstName(submitData.firstName);
            submit(submitData);
        } else {
            setAdminError(true);
        }
        props.closeModal();
    };

    const handleCheckboxChange = (e) => {
        if (e.target.value === 'false') {
            setCheckbox(true);
        } else {
            setCheckbox(false);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label>Förnamn<br />
                <input
                    {...register("firstName", {
                        required: "Fältet är obligatoriskt"
                    })}
                    type="text"
                />
            </label>
            <div>
                <small className='error text-danger'>{errors.lastName?.message}</small>
            </div>
            <label>Efternamn<br />
                <input
                    {...register("lastName", {
                        required: "Fältet är obligatoriskt"
                    })}
                    type="text"
                />
            </label>
            <div>
                <small className='error text-danger'>{errors.lastName?.message}</small>
            </div>
            <label>E-post<br />
                <input
                    {...register("email", {
                        required: "Fältet är obligatoriskt"
                    })}
                    type="email"
                />
            </label>
            <div>
                <small className='error text-danger'>{errors.email?.message}</small>
            </div>
            <label>Lösenord<br />
                <input
                    {...register("password", {
                        required: "Fältet är obligatoriskt"
                    })}
                    type="password"
                />
            </label>
            <div>
                <small className='error text-danger'>{errors.password?.message}</small>
            </div>

            <label>Administrator<br />
                <input
                    {...register("isAdmin")}
                    type="checkbox"
                    checked={checkbox}
                    value={checkbox}
                    onChange={(e) => { handleCheckboxChange(e) }}
                />
                <input
                    {...register("createAdminPassword")}
                    type="password"
                    placeholder="Lösenord för att bli admin"
                    disabled={!checkbox}
                />
            </label>
            <div>
                <small className='error text-danger'>{errors.admin?.message}</small>
            </div>

            <div id="btnContainer">
                <button type="submit" className="btn btn-primary btn-sm mx-2">Skapa CV-konto</button>
            </div>
            {adminError ?
                <p id="loginError" className='text-danger'>Fel lösenord för att bli Admin</p>
                :
                null}
        </form>
    )
}
export default NewCvForm;